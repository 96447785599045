import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Image, Spinner, Card, Badge } from 'react-bootstrap';
import { numberWithCommas } from "../../shared-functions.js";
import { db } from "../../use-firebase.js";
import { collection, getDocs, where, query, updateDoc, doc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import Moment from 'react-moment';
import { ShieldCheck, ShieldSlash, LinkedinLogo, TwitterLogo, Eye, EyeSlash } from "phosphor-react";
import ReactPlayer from 'react-player/lazy';
import StarRatings from 'react-star-ratings';
import { YouTubeEmbed, TwitterEmbed, LinkedInEmbed } from 'react-social-media-embed';
import MuxPlayer from '@mux/mux-player-react';

export default function PostCard(props) {

  const navigate = useNavigate();
  const [localMuxPlaybackId, setLocalMuxPlaybackId] = useState(null);

  useEffect(() => {
    if (props && props.post && props.post.muxId) {
      if (props.post.muxPlaybackId) {
        setLocalMuxPlaybackId(props.post.muxPlaybackId);
      } else {
        getMuxPlaybackId(props.post.muxId);
      }
    }
  }, [props]);

  const getMuxPlaybackId = (_id) => {
    getDocs(query(collection(db, "muxvideos"), where("id", "==", _id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        if (_doc.data().status === 'ready' && _doc.data().asset && _doc.data().asset.playback_ids) {
          setLocalMuxPlaybackId(_doc.data().asset.playback_ids[0].id);
          if (!props.public) {
            saveMuxPlaybackIdToPost(_doc.data().asset.playback_ids[0].id);
          }
        }
      });
    });
  };

  const saveMuxPlaybackIdToPost = (_id) => {
    getDocs(query(collection(db, "posts"), where("id", "==", props.post.id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        updateDoc(doc(db, "posts", _doc.id), { muxPlaybackId: _id });
      });
    });
  };

  if (!props || !props.post) {
    return (
      <Row style={{ justifyContent: 'center' }}>
        <Spinner animation="border" role="status" />
      </Row>
    )
  }

  return (
    <Card style={{ cursor: 'pointer', padding: 0, margin: 10, backgroundColor: '#EEEEEE77' }}>
      { localMuxPlaybackId &&
        <div>
          <MuxPlayer
            streamType="on-demand"
            playbackId={localMuxPlaybackId}
            primaryColor="#CCCCCC"
            width="100%"
            metadata={{
              video_id: props.post.muxId,
              video_title: props.post.name
            }}
          />
        </div>
      }
      <Card.Body style={{ padding: 20 }} onClick={() => { if (!props.public) { navigate('/post/' + props.post.id) }} }>
        { props.post.rating && props.post.rating !== 0 &&
          <div style={{ marginTop: -10, marginBottom: 10 }}>
            <StarRatings
              rating={props.post.rating}
              starRatedColor="orange"
              numberOfStars={5}
              name='rating'
              starDimension={24}
              starSpacing={1}
            />
          </div>
        }
        { props.post.comment &&
          <h5 style={{ marginBottom: 10 }}>"{ props.post.comment }"</h5>
        }
        <Card.Title><h5 style={{ marginTop: 10 }}>{ props.post.name }</h5></Card.Title>
        <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: -15 }}>
          <Col style={{ padding: 0 }}>
            <p className="small" style={{ marginTop: 5 }}>{ props.post.jobTitle }</p>
            { (props.post.linkedinUsername || props.post.twitterUsername) &&
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                { props.post.linkedinUsername &&
                  <a href={'https://linkedin.com/in/' + props.post.linkedinUsername} target="_blank"><LinkedinLogo size={18} style={{ marginRight: 8 }}/></a>
                }
                { props.post.twitterUsername &&
                  <a href={'https://twitter.com/' + props.post.twitterUsername} target="_blank"><TwitterLogo size={18} /></a>
                }
              </Row>
            }
            <p className="small muted" style={{ marginTop: 10 }}><Moment fromNow>{ props.post.createdTs }</Moment></p>
            { props.post.tags &&
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
                {
                  props.post.tags.map((tag) => {
                    return (
                      <p className="small muted" style={{ marginRight: 10 }}>#{ tag.text }</p>
                    )
                  })
                }
              </Row>
            }
          </Col>
        </Row>
        { !props.public &&
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10, marginLeft: 0, marginRight: 0 }}>
            { props.post.approved &&
              <ShieldCheck size={18} weight="bold" color={"green"} style={{ marginRight: 10 }} />
            }
            { !props.post.approved &&
              <ShieldSlash size={18} weight="bold" color={"#AAAAAA"} style={{ marginRight: 10 }} />
            }
            { props.post.publicVisibility &&
              <Eye size={18} weight="bold" color={"green"} />
            }
            { !props.post.publicVisibility &&
              <EyeSlash size={18} weight="bold" color={"#AAAAAA"} />
            }
          </Row>
        }
      </Card.Body>
    </Card>
  );

}

// { props.post.url && props.post.url.length > 0 && false &&
//   <div style={{ marginBottom: 10 }}>
//     <ReactPlayer
//         id={"id-" + props.post.id}
//         url={props.post.url}
//         width="100%"
//         controls
//         volume={1}
//     />
//   </div>
// }

// { props.post.type === 'photo' && !props.collection &&
//   <Card.Img variant="top" src={props.post.url} style={{ objectFit: 'cover', borderRadius: 8 }} />
// }
// { props.post.type === 'photo' && props.collection &&
//   <Card.Img variant="top" src={props.post.url} style={{ objectFit: 'cover', height: 200, borderRadius: 8 }} />
// }
// { props.post.type === 'url' && props.post.source === 'youtube' && props.post.url && props.post.url.length > 0 &&
//   <div style={{ display: 'flex', justifyContent: 'center' }}>
//     <YouTubeEmbed url={props.post.url} width="100%" height={'340px'} />
//   </div>
// }
// { props.post.type === 'url' && props.post.source === 'twitter' && props.post.url && props.post.url.length > 0 &&
//   <div style={{ display: 'flex', justifyContent: 'center', border: 'none' }}>
//     <TwitterEmbed url={props.post.url} width="100%" />
//   </div>
// }
// { props.post.type === 'url' && props.post.source === 'linkedin' && props.post.url && props.post.url.length > 0 &&
//   <div style={{ display: 'flex', justifyContent: 'center', padding: 5, border: '1px solid #cccccc', borderRadius: 10 }}>
//     <LinkedInEmbed url={props.post.url} width="100%" />
//   </div>
// }
// { props.post.type === 'video' && props.post.url && props.post.url.length > 0 &&
//   <ReactPlayer
//       id={"id-" + props.post.id}
//       url={props.post.url}
//       width="100%"
//       controls
//       volume={1}
//   />
// }

// if (props.post.type === 'quote') {
//   return (
//     <div style={{ padding: 20, cursor: 'pointer' }}>
//       { props.post.rating && props.post.rating > 0 &&
//         <div style={{ marginBottom: 20 }}>
//           <StarRatings
//             rating={props.post.rating}
//             starRatedColor="orange"
//             numberOfStars={5}
//             name='rating'
//             starDimension={24}
//             starSpacing={1}
//           />
//         </div>
//       }
//       <h4 onClick={() => { if (!props.public) { navigate('/post/' + props.post.id) }} }>"{ props.post.quote }"</h4>
//       <h6 style={{ marginTop: 20 }}>{ props.post.name } { props.post.linkedinUsername && <a href={'https://linkedin.com/in/' + props.post.linkedinUsername} target="_blank"><LinkedinLogo size={22} color={"blue"} /></a> }</h6>
//       <p className="small" style={{ marginTop: 2 }}>{ props.post.jobTitle }</p>
//       <p className="small muted" style={{ marginTop: 0 }}><Moment fromNow>{ props.post.createdTs }</Moment></p>
//       { props.post.tags &&
//         <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
//           {
//             props.post.tags.map((tag) => {
//               return (
//                 <p className="small muted" style={{ marginRight: 10 }}>#{ tag.text }</p>
//               )
//             })
//           }
//         </Row>
//       }
//       { !props.public &&
//         <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10, marginLeft: 0, marginRight: 0 }}>
//           { props.post.approved &&
//             <ShieldCheck size={18} weight="bold" color={"green"} style={{ marginRight: 10 }} />
//           }
//           { !props.post.approved &&
//             <ShieldSlash size={18} weight="bold" color={"#AAAAAA"} style={{ marginRight: 10 }} />
//           }
//           { props.post.publicVisibility &&
//             <Eye size={18} weight="bold" color={"green"} />
//           }
//           { !props.post.publicVisibility &&
//             <EyeSlash size={18} weight="bold" color={"#AAAAAA"} />
//           }
//         </Row>
//       }
//     </div>
//   )
// }
