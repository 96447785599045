import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail, useMediaQuery } from './shared-functions.js';
import { db } from "./use-firebase.js";
import { useRequireAuth } from "./use-require-auth.js";
import { collection, addDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Image, Modal, Form } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import {  } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Library() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  const doToast = (text) => {
    toast(text, {
      position: "top-center",
      theme: "dark",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <h3>Library</h3>
          </Row>

        </Col>
      </Row>
    </Row>
  )

  return (
    <Row style={{ justifyContent: 'center' }}>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Footer />

    </Row>
  )

}
