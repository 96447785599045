import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateRandomString, useMediaQuery } from './shared-functions.js';
import { db, storage } from "./use-firebase.js";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useRequireAuth } from "./use-require-auth.js";
import { collection, getDocs, where, query, addDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Table, Spinner, Modal, Form, Image, InputGroup, Badge, Card } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { Copy, PencilSimple, UploadSimple, DownloadSimple, Quotes, Envelope, Eye, EyeSlash, CodeSimple, ArrowLeft, YoutubeLogo, TwitterLogo, LinkedinLogo, Checks } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PostCard from "./components/cards/Post.js";
import ReactPlayer from 'react-player';
import Moment from 'react-moment';
import { XMasonry, XBlock } from "react-xmasonry";
import { WithContext as ReactTags } from 'react-tag-input';
import { YouTubeEmbed, TwitterEmbed, LinkedInEmbed } from 'react-social-media-embed';
import StarRatings from 'react-star-ratings';
import * as UpChunk from '@mux/upchunk';
import MuxPlayer from '@mux/mux-player-react';

export default function Showcase() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const props = useParams();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [showcase, setShowcase] = useState(null);
  const [posts, setPosts] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [quoteModal, setQuoteModal] = useState(false);
  const [embedModal, setEmbedModal] = useState(false);
  const [muxId, setMuxId] = useState(null);
  const [muxPlaybackId, setMuxPlaybackId] = useState(null);
  const [showPreviewVideo, setShowPreviewVideo] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [importUrl, setImportUrl] = useState(null);
  const [importSource, setImportSource] = useState('youtube');
  const [validated, setValidated] = useState(false);
  const [comment, setComment] = useState(null);
  const [quote, setQuote] = useState(null);
  const [name, setName] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [linkedinUsername, setLinkedinUsername] = useState(null);
  const [twitterUsername, setTwitterUsername] = useState(null);
  const [rating, setRating] = useState(0);
  const [tags, setTags] = useState([]);
  const [filterTag, setFilterTag] = useState('All');
  const [photoProgress, setPhotoProgress] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [postTags, setPostTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const KeyCodes = { comma: 188, enter: 13 }
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const fileInput = useRef(null);
  const [showPublicOnly, setShowPublicOnly] = useState(false);

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {
      // get showcase
      if (props === undefined || props.id === undefined) {
        navigate('/notfound');
      } else {
        getDocs(query(collection(db, "showcases"), where("id", "==", props.id), where("workspaceId", "==", auth.workspace.id))).then((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            querySnapshot.forEach((doc) => {
              setShowcase(doc.data());
              getDocs(query(collection(db, "posts"), where("showcaseId", "==", doc.data().id), where("workspaceId", "==", auth.workspace.id))).then((querySnapshot2) => {
                let _posts = []
                let _tags = [];
                querySnapshot2.forEach((doc2) => {
                  _posts.push(doc2.data());
                  if (doc2.data().tags) {
                    doc2.data().tags.forEach((t, i) => {
                      if (!_tags.includes(t.text)) {
                        _tags.push(t.text);
                      }
                    });
                  }
                });
                setTags(_tags);
                setPosts(_posts.sort((a, b) => (b.createdTs > a.createdTs) ? 1 : -1));
              });
            });
          } else {
            navigate('/notfound');
          }
        });
      }
    }
    window.scrollTo(0,0);
  }, [auth]);

  const handleNewValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        saveVideoAsPost();
      }
      setValidated(true);
    }
  };

  const saveVideoAsPost = () => {
    let newVideoPost = {
      id: generateRandomString(16),
      showcaseId: showcase.id,
      workspaceId: showcase.workspaceId,
      name: name,
      rating: rating,
      comment: comment,
      jobTitle: jobTitle,
      muxId: muxId,
      muxPlaybackId: muxPlaybackId,
      linkedinUsername: linkedinUsername,
      twitterUsername: twitterUsername,
      tags: postTags,
      type: 'video',
      approved: true,
      publicVisibility: true,
      createdTs: Date.now(),
      visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
    };
    addDoc(collection(db, "posts"), newVideoPost).then(() => {
      doToastSuccess("Video saved!");
      setUploadModal(false);
      window.location.reload(true);
    })
  };

  const getMuxPlaybackId = (_id) => {
    getDocs(query(collection(db, "muxvideos"), where("id", "==", _id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().status === 'ready' && doc.data().asset && doc.data().asset.playback_ids) {
          setMuxPlaybackId(doc.data().asset.playback_ids[0].id);
        }
      });
    });
  };

  const handleVideoChange = e => {
    if (e.target.files[0]) {
      const video = e.target.files[0];
      if ((video.type === "video/mpg" || video.type === "video/mp4" || video.type === "video/mov" || video.type === "video/quicktime") && (video.size/1000000 < 200)) {
        setVideoError(false);
        // handleVideoUpload(video);
        handleVideoUploadToMux(video);
      } else {
        setVideoError(true);
      }
    }
  };

  const handleVideoUploadToMux = (video) => {
    setShowPreviewVideo(false);
    fetch('https://us-central1-nikos-app.cloudfunctions.net/getMuxLink', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        key: 'value',
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("response", responseJson);
      let uploadUrl = responseJson.url;
      setMuxId(responseJson.id)
      // upload
      const upload = UpChunk.createUpload({
        endpoint: uploadUrl, // Authenticated url
        file: video, // File object with your video file’s properties
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });
      // Subscribe to events
      upload.on('error', error => {
        // console.log("error", error.detail);
        doToastError("Something went wrong. Try again.")
      });
      upload.on('progress', progress => {
        setVideoProgress(progress.detail);
        // console.log("progress", progress.detail);
      });
      upload.on('success', () => {
        setShowPreviewVideo(true);
        const timer = setTimeout(() => {
          getMuxPlaybackId(responseJson.id);
        }, 10000);
        return () => clearTimeout(timer);
      });
    })
  }

  const handleDelete = i => {
    let _tags = postTags.filter((tag, index) => index !== i)
    setPostTags(_tags);
  };

  const handleAddition = tag => {
    let _tag = { id: tag.id, text: tag.text.replace(/#/g,'') }
    let newTags = [...postTags, _tag];
    setPostTags(newTags)
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = postTags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setPostTags(newTags);
  };

  const handleTagClick = index => {
    // console.log('The tag at index ' + index + ' was clicked');
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-right",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-right",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (showcase === null) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <p className="small muted" style={{ textAlign: 'left' }}><a href={"/showcases"}><ArrowLeft /> Back to my showcases</a></p>
          <h3 style={{ marginTop: 10, textAlign: 'left' }}>{ showcase.name }</h3>
          <Row style={{ flexDirection: 'row', justifyContent: 'left', alignItems: 'center', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
            <Col xs={10} sm={9} md={8} lg={7} xl={5} style={{ textAlign: 'left', padding: 0 }}>
              <Badge bg={ showcase.publicVisibility ? 'success' : 'dark' } style={{ textAlign: 'center', color: '#EEEEEE' }}>{ showcase.publicVisibility ? 'Public' : 'Hidden' }</Badge>
              <p className="small muted" style={{ marginTop: 10 }}>{ showcase.description }</p>
            </Col>
          </Row>
          <hr />
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10, marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <p className="small" style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/request/" + showcase.gatherCode); doToastSuccess('Request URL copied to clipboard') }}><Copy size={14} /> Copy request URL</p>
            <a href={"/p/" + showcase.id} target="_blank"><p className="small" style={{ marginRight: 20, cursor: 'pointer' }}><Eye size={14} /> Open public page</p></a>
            <p className="small" style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/p/" + showcase.id); doToastSuccess('Public page URL copied to clipboard') }}><Copy size={14} /> Copy public URL</p>
            <p className="small" style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => setUploadModal(true)}><UploadSimple size={14} /> Upload { showcase.showcaseType }</p>
            <p className="small" style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => navigate('/showcases/edit/' + showcase.id)}><PencilSimple size={14} /> Edit showcase</p>
            <p className="small" style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => { setEmbedModal(true) }}><CodeSimple size={14} /> Copy embed code</p>
          </Row>
          <hr />
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <Col xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: 0 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                <p className="small" style={{ marginRight: 10, marginBottom: 10, borderRadius: 20, backgroundColor: filterTag === "All" ? 'lightgreen' : '#eeeeee', padding: '5px 15px', cursor: 'pointer' }} onClick={() => setFilterTag('All')}>All</p>
                {
                  tags.map((t, id) => {
                    return (
                      <p className="small" style={{ marginRight: 10, marginBottom: 5, borderRadius: 20, backgroundColor: t == filterTag ? 'lightgreen' : '#eeeeee', padding: '5px 15px', cursor: 'pointer' }} onClick={() => setFilterTag(t)}>{ t }</p>
                    )
                  })
                }
              </Row>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ textAlign: 'right' }}>
              { showPublicOnly &&
                <Eye size="22" style={{ cursor: 'pointer' }} onClick={() => setShowPublicOnly(false)} />
              }
              { !showPublicOnly &&
                <EyeSlash size="22" style={{ cursor: 'pointer' }} onClick={() => setShowPublicOnly(true)} />
              }
            </Col>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              { posts.length === 0 &&
                <p className="small" style={{ marginTop: 10, textAlign: 'center' }}>No posts yet</p>
              }
              { posts.length > 0 &&
                <XMasonry maxColumns={"Infinity"} responsive="true" center="true" targetBlockWidth={200}>
                    {
                      posts.map((p) => {
                        if (filterTag === 'All' || (p.tags && p.tags.some(t => t.text === filterTag))) {
                          if (!showPublicOnly || p.publicVisibility) {
                            return (
                              <XBlock key={ p.id } width={2}>
                                <PostCard post={p} />
                              </XBlock>
                            )
                          }
                        }
                      })
                    }
                </XMasonry>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={uploadModal}
        centered
        size="md"
        fullscreen='lg-down'
        style={{ backgroundColor: '#111111AA' }}
        onHide={() => setUploadModal(false)}>
        <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
          <h4>Upload</h4>
            <Form noValidate validated={validated} onSubmit={handleNewValidation} style={{ marginTop: 0 }}>
            <InputGroup className="mb-3">
              <Form.Control type="file" onChange={handleVideoChange} ref={fileInput} style={{ display: 'none' }}/>
            </InputGroup>
            <Card style={{ backgroundColor: '#eeeeee', height: 80, cursor: 'pointer' }} onClick={() => fileInput.current.click()}>
              <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                <h5 className="muted">Upload a video</h5>
              </Row>
            </Card>
            { videoError &&
              <p className="text-danger small">Upload MP4/MPG/MOV files less than 200MB in size</p>
            }
            { videoProgress > 0 && videoProgress < 100 &&
              <p className="small muted">Uploading ... { parseInt(videoProgress) }%</p>
            }
            { showPreviewVideo && !muxPlaybackId &&
              <Card style={{ height: 200, marginTop: 20, backgroundColor: '#EEEEEE' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                  <Checks size={22} color="#02ca4f" weight="bold" />
                  <p className="bold small" style={{ color: '#02ca4f', marginTop: 5 }}>Uploaded</p>
                </Row>
              </Card>
            }
            { showPreviewVideo && muxPlaybackId &&
              <div style={{ marginTop: 20 }}>
                <MuxPlayer
                  streamType="on-demand"
                  playbackId={muxPlaybackId}
                  primaryColor="#CCCCCC"
                  width="100%"
                  metadata={{
                    video_id: muxId,
                    video_title: name
                  }}
                />
              </div>
            }
            <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
              <h6>Add a Rating</h6>
            </Form.Label>
            <div>
              <StarRatings
                rating={rating}
                starRatedColor="orange"
                changeRating={(val) => setRating(val)}
                numberOfStars={5}
                name='rating'
                starDimension={30}
                starSpacing={1}
                style={{ width: 100 }}
              />
            </div>
            <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
              <h6>Add a Comment</h6><p className="small muted">Optional</p>
            </Form.Label>
            <Form.Group controlId="name">
              <Form.Control as="textarea" type="text" maxLength="500" rows={3} value={comment} onChange={(e) => setComment(e.target.value)} />
            </Form.Group>
            { showcase.extraName &&
              <div>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6>Name</h6>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Ava Ko" value={name} onChange={(e) => setName(e.target.value)} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid name 3 to 96 characters in length
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            { showcase.extraJobTitle &&
              <div>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6>Job title</h6>
                </Form.Label>
                <Form.Group controlId="jobtitle">
                  <Form.Control as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Director of Marketing" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid job title 3 to 96 characters in length
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            { showcase.extraLinkedin &&
              <div>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6>LinkedIn Handle</h6>
                </Form.Label>
                <Form.Group controlId="linkedin">
                  <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={linkedinUsername} onChange={(e) => setLinkedinUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid LinkedIn handle up to 96 characters long
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            { showcase.extraTwitter &&
              <div>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6>Twitter Handle</h6>
                </Form.Label>
                <Form.Group controlId="linkedin">
                  <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={twitterUsername} onChange={(e) => setTwitterUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid LinkedIn handle up to 96 characters long
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            <h6 style={{ marginTop: 20, marginBottom: 5 }}>Tags</h6>
            <div style={{ backgroundColor: 'transparent' }}>
              <ReactTags
                tags={postTags}
                suggestions={suggestions}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                handleTagClick={handleTagClick}
                inline={true}
                placeholder={"+ Add tags"}
                inputFieldPosition="inline"
                autofocus={false}
                autocomplete
              />
            </div>
            <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Button variant="tertiary" onClick={() => setUploadModal(false)}>Cancel</Button>
              <Button variant="primary" type="submit" disabled={(!showPreviewVideo && !comment) ? true : false }>Save</Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={embedModal}
        centered
        size="md"
        fullscreen='lg-down'
        style={{ backgroundColor: '#111111AA' }}
        onHide={() => setEmbedModal(false)}>
        <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
          <h4>Embed code</h4>
          <p className="small" style={{ marginTop: 10 }}>Copy the embed code by clicking the button below. Paste the code in your website's HTML as is. If you use a web design tool such as Webflow, paste the code inside it's Embed block.</p>
          <Form.Control as="textarea" type="text" rows={10} style={{ marginTop: 20 }} value={'<script type="text/javascript" src="https://firebasestorage.googleapis.com/v0/b/nikos-app.appspot.com/o/js%2FiframeResizer.js?alt=media&token=ee65c4bb-1e4f-4c71-821f-c2d69c524de2"></script><iframe id="nikos-ai-showcase" src="https://app.nikos.ai/p/' + showcase.id + '/embed" title="' + showcase.name + ' - Nikos" frameborder="0" scrolling="no" allowtransparency="true" width="100%"></iframe><script type="text/javascript">iFrameResize({log: false, enablePublicMethods: true, heightCalculationMethod: "lowestElement", checkOrigin: false}, "#nikos-ai-showcase");</script>'} />
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
            <p className="small" style={{ marginTop: 10, padding: '5px 10px', backgroundColor: '#EEEEEE', cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText('<script type="text/javascript" src="https://firebasestorage.googleapis.com/v0/b/nikos-app.appspot.com/o/js%2FiframeResizer.js?alt=media&token=ee65c4bb-1e4f-4c71-821f-c2d69c524de2"></script><iframe id="nikos-ai-showcase" src="https://app.nikos.ai/p/' + showcase.id + '/embed" title="' + showcase.name + ' - Nikos" frameborder="0" scrolling="no" allowtransparency="true" width="100%"></iframe><script type="text/javascript">iFrameResize({log: false, enablePublicMethods: true, heightCalculationMethod: "lowestElement", checkOrigin: false}, "#nikos-ai-showcase");</script>'); doToastSuccess('Copied embed code to clipboard') }}><Copy size={14} /> Copy code to clipboard</p>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 30 }}>
            <Button variant="tertiary" onClick={() => setEmbedModal(false)}>Close</Button>
          </Row>
        </Modal.Body>
      </Modal>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  )

}


// Upload photos and quotes separately
// { (showcase.videoContent || showcase.photoContent) &&
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setUploadModal(true)}><UploadSimple size={14} /> Upload</p>
// }
// <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setImportModal(true)}><DownloadSimple size={14} /> Import</p>
// { showcase.quoteContent &&
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setQuoteModal(true)}><Quotes size={14} /> Quote</p>
// }
// <div>
//   <h6 style={{ marginTop: 20 }}>Upload a photo</h6>
//   <InputGroup className="mb-3" style={{ marginTop: 10 }}>
//     <Form.Control type="file" onChange={handlePhotoChange} style={{ border: 0, padding: 0, backgroundColor: '#FFFFFF' }}/>
//   </InputGroup>
//   { photoError &&
//     <p className="text-danger">Upload PNGs/JPGs less than 2MB in size</p>
//   }
//   { photoProgress > 0 && photoProgress < 100 &&
//     <p className="small muted">Uploading ... { parseInt(photoProgress) }%</p>
//   }
//   { photoUrl &&
//     <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
//       <Image src={ photoUrl } style={{ maxHeight: 100, objectFit: 'cover' }} />
//     </Row>
//   }
// </div>

// <Modal
//   show={importModal}
//   centered
//   size="md"
//   fullscreen='lg-down'
//   style={{ backgroundColor: '#111111AA' }}
//   onHide={() => setImportModal(false)}>
//   <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
//     <h4>Import</h4>
//     <Form noValidate validated={validated} onSubmit={handleImportValidation} style={{ marginTop: 20 }}>
//       <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
//         <YoutubeLogo size={32} color={ importSource === 'youtube' ? 'green' : '#EEEEEE' } style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => setImportSource('youtube')}/>
//         <TwitterLogo size={32} color={ importSource === 'twitter' ? 'green' : '#CCCCCC' } style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => setImportSource('twitter')}/>
//         <LinkedinLogo size={32} color={ importSource === 'linkedin' ? 'green' : '#CCCCCC' } style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => setImportSource('linkedin')}/>
//       </Row>
//       <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//         <h6>{ importSource } URL</h6>
//         { importSource === 'youtube' &&
//           <p className="small muted">Open the YouTube video in a browser window. Copy the URL from the address bar. You can also click Share & then Copy.</p>
//         }
//         { importSource === 'twitter' &&
//           <p className="small muted">Open a Twitter tweet in a browser window. Copy the link to the tweet from the address bar.</p>
//         }
//         { importSource === 'linkedin' &&
//           <p className="small muted">The url must be be retrieved from the "Embed this post" option for the desired post. Use the "src" attribute of the iframe from the embed code of the post.</p>
//         }
//       </Form.Label>
//       <Form.Group controlId="url">
//         <Form.Control required as="input" type="text" minLength="3" maxLength="96" value={importUrl} onChange={(e) => setImportUrl(e.target.value)} />
//         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         <Form.Control.Feedback type="invalid">
//           Enter a valid URL
//         </Form.Control.Feedback>
//       </Form.Group>
//       { importSource === 'youtube' && importUrl &&
//         <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
//           <YouTubeEmbed url={importUrl} width={'100%'} height={220} />
//         </div>
//       }
//       { importSource === 'twitter' && importUrl &&
//         <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
//           <TwitterEmbed url={importUrl} width={'100%'} />
//         </div>
//       }
//       { importSource === 'linkedin' && importUrl &&
//         <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
//           <LinkedInEmbed url={importUrl} width={'100%'} />
//         </div>
//       }
//       <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//         <h6>Additional Comment</h6><p className="small muted">Optional</p>
//       </Form.Label>
//       <Form.Group controlId="name">
//         <Form.Control as="input" type="text" maxLength="96" value={comment} onChange={(e) => setComment(e.target.value)} />
//       </Form.Group>
//       { showcase.extraName &&
//         <div>
//           <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//             <h6>Author's Name</h6>
//           </Form.Label>
//           <Form.Group controlId="name">
//             <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Ava Ko" value={name} onChange={(e) => setName(e.target.value)} />
//             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//             <Form.Control.Feedback type="invalid">
//               Enter a valid name 3 to 96 characters in length
//             </Form.Control.Feedback>
//           </Form.Group>
//         </div>
//       }
//       { showcase.extraJobTitle &&
//         <div>
//           <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//             <h6>Author's Job title</h6>
//           </Form.Label>
//           <Form.Group controlId="jobtitle">
//             <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Director of Marketing" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
//             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//             <Form.Control.Feedback type="invalid">
//               Enter a valid job title 3 to 96 characters in length
//             </Form.Control.Feedback>
//           </Form.Group>
//         </div>
//       }
//       { showcase.extraLinkedin &&
//         <div>
//           <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//             <h6>LinkedIn Handle</h6>
//           </Form.Label>
//           <Form.Group controlId="linkedin">
//             <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={linkedinUsername} onChange={(e) => setLinkedinUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
//             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//             <Form.Control.Feedback type="invalid">
//               Enter a valid LinkedIn handle up to 96 characters long
//             </Form.Control.Feedback>
//           </Form.Group>
//         </div>
//       }
//       <h6 style={{ marginTop: 20, marginBottom: 5 }}>Tags</h6>
//       <div style={{ backgroundColor: 'transparent' }}>
//         <ReactTags
//           tags={postTags}
//           suggestions={suggestions}
//           delimiters={delimiters}
//           handleDelete={handleDelete}
//           handleAddition={handleAddition}
//           handleDrag={handleDrag}
//           handleTagClick={handleTagClick}
//           inline={true}
//           placeholder={"+ Add tags"}
//           inputFieldPosition="inline"
//           autofocus={false}
//           autocomplete
//         />
//       </div>
//       <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
//         <Button variant="tertiary" onClick={() => setImportModal(false)}>Cancel</Button>
//         <Button variant="primary" type="submit" style={{ marginTop: 20 }} disabled={!importUrl ? true : false }>Save</Button>
//       </Row>
//     </Form>
//   </Modal.Body>
// </Modal>
// <Modal
//   show={quoteModal}
//   centered
//   size="md"
//   fullscreen='lg-down'
//   style={{ backgroundColor: '#111111AA' }}
//   onHide={() => setQuoteModal(false)}>
//   <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
//     <h4>Quote</h4>
//     <Form noValidate validated={validated} onSubmit={handleQuoteValidation} style={{ marginTop: 20 }}>
//       <Form.Label style={{ marginTop: 10, marginBottom: 5 }}>
//         <h6>Quote</h6>
//         <p className="small muted">Enter the quote text.</p>
//       </Form.Label>
//       <Form.Group controlId="url">
//         <Form.Control required as="textarea" type="text" minLength="3" maxLength="500" value={quote} onChange={(e) => setQuote(e.target.value)} />
//         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         <Form.Control.Feedback type="invalid">
//           Enter a valid text 3 to 500 characters long
//         </Form.Control.Feedback>
//       </Form.Group>
//       <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//         <h6>Name</h6>
//       </Form.Label>
//       <Form.Group controlId="name">
//         <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Ava Ko" value={name} onChange={(e) => setName(e.target.value)} />
//         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         <Form.Control.Feedback type="invalid">
//           Enter a valid name 3 to 96 characters in length
//         </Form.Control.Feedback>
//       </Form.Group>
//       <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//         <h6>Job title</h6>
//       </Form.Label>
//       <Form.Group controlId="jobtitle">
//         <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Director of Marketing" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
//         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         <Form.Control.Feedback type="invalid">
//           Enter a valid job title 3 to 96 characters in length
//         </Form.Control.Feedback>
//       </Form.Group>
//       <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//         <h6>LinkedIn Handle</h6>
//       </Form.Label>
//       <Form.Group controlId="linkedin">
//         <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={linkedinUsername} onChange={(e) => setLinkedinUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
//         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         <Form.Control.Feedback type="invalid">
//           Enter a valid LinkedIn handle up to 96 characters long
//         </Form.Control.Feedback>
//       </Form.Group>
//       <h6 style={{ marginTop: 20, marginBottom: 5 }}>Tags</h6>
//       <div style={{ backgroundColor: 'transparent' }}>
//         <ReactTags
//           tags={postTags}
//           suggestions={suggestions}
//           delimiters={delimiters}
//           handleDelete={handleDelete}
//           handleAddition={handleAddition}
//           handleDrag={handleDrag}
//           handleTagClick={handleTagClick}
//           inline={true}
//           placeholder={"+ Add tags"}
//           inputFieldPosition="inline"
//           autofocus={false}
//           autocomplete
//         />
//       </div>
//       <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
//         <Button variant="tertiary" onClick={() => setQuoteModal(false)}>Cancel</Button>
//         <Button variant="primary" type="submit" style={{ marginTop: 20 }} disabled={!quote || quote.length === 0 ? true : false }>Save</Button>
//       </Row>
//     </Form>
//   </Modal.Body>
// </Modal>

// const saveUrlAsPost = () => {
//   let newUrlPost = {
//     id: generateRandomString(16),
//     showcaseId: showcase.id,
//     workspaceId: showcase.workspaceId,
//     comment: comment,
//     name: name,
//     jobTitle: jobTitle,
//     linkedinUsername: linkedinUsername,
//     twitterUsername: twitterUsername,
//     source: importSource,
//     url: importUrl,
//     tags: postTags,
//     type: 'url',
//     approved: true,
//     publicVisibility: true,
//     createdTs: Date.now(),
//     visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
//   };
//   addDoc(collection(db, "posts"), newUrlPost).then(() => {
//     doToastSuccess("Import saved!");
//     setImportUrl(null);
//     setImportModal(false);
//     window.location.reload(true);
//   })
// };
//
// const saveQuoteAsPost = () => {
//   let newUrlPost = {
//     id: generateRandomString(16),
//     showcaseId: showcase.id,
//     workspaceId: showcase.workspaceId,
//     name: name,
//     jobTitle: jobTitle,
//     linkedinUsername: linkedinUsername,
//     quote: quote,
//     type: 'quote',
//     approved: true,
//     publicVisibility: true,
//     createdTs: Date.now(),
//     visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
//   };
//   addDoc(collection(db, "posts"), newUrlPost).then(() => {
//     doToastSuccess("Quote saved!");
//     setQuote(null);
//     setQuoteModal(false);
//     window.location.reload(true);
//   })
// };
//
// const handlePhotoChange = e => {
//   if (e.target.files[0]) {
//     const photo = e.target.files[0];
//     // console.log(cover);
//     // jpg or png images, less than 2MB
//     if ((photo.type === "image/png" || photo.type === "image/jpeg" || photo.type === "image/jpg") && (photo.size/1000000 < 2)) {
//       setPhotoError(false);
//       handlePhotoUpload(photo);
//     } else {
//       setPhotoError(true);
//     }
//   }
// };
//
// const handlePhotoUpload = (cover) => {
//   let id = generateRandomString(24);
//   const metadata = { contentType: cover.type, customMetadata: { ruleCode: process.env.REACT_APP_FIREBASE_STORAGE_CODE }};
//   const storageRef = ref(storage, 'photos/' + id);
//   const uploadTask = uploadBytesResumable(storageRef, cover, metadata);
//   uploadTask.on('state_changed',
//     (snapshot) => {
//       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//       // console.log('Upload is ' + progress + '% done');
//       setPhotoProgress(progress);
//       switch (snapshot.state) {
//         case 'paused':
//           break;
//         case 'running':
//           break;
//       }
//     },
//     (error) => {
//       console.log("photo upload error");
//     },
//     () => {
//       getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
//         setPhotoUrl(downloadUrl);
//       });
//     }
//   );
// };

// const handleImportValidation = (event) => {
//   if (event) {
//     const form = event.currentTarget;
//     event.preventDefault();
//     event.stopPropagation();
//     if (form.checkValidity() === true) {
//       saveUrlAsPost();
//     }
//     setValidated(true);
//   }
// };
//
// const handleQuoteValidation = (event) => {
//   if (event) {
//     const form = event.currentTarget;
//     event.preventDefault();
//     event.stopPropagation();
//     if (form.checkValidity() === true) {
//       saveQuoteAsPost();
//     }
//     setValidated(true);
//   }
// };
//
// const savePhotoAsPost = () => {
//   let newPhotoPost = {
//     id: generateRandomString(16),
//     showcaseId: showcase.id,
//     workspaceId: showcase.workspaceId,
//     comment: comment,
//     name: name,
//     jobTitle: jobTitle,
//     linkedinUsername: linkedinUsername,
//     twitterUsername: twitterUsername,
//     url: photoUrl,
//     tags: postTags,
//     type: 'photo',
//     approved: true,
//     publicVisibility: true,
//     createdTs: Date.now(),
//     visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
//   };
//   addDoc(collection(db, "posts"), newPhotoPost).then(() => {
//     doToastSuccess("Photo saved!");
//     setPhotoUrl(null);
//     setUploadModal(false);
//     window.location.reload(true);
//   })
// };
// const handleVideoUpload = (video) => {
//   let id = generateRandomString(24);
//   const metadata = { contentType: video.type, customMetadata: { ruleCode: process.env.REACT_APP_FIREBASE_STORAGE_CODE }};
//   const storageRef = ref(storage, 'videos/' + id);
//   const uploadTask = uploadBytesResumable(storageRef, video, metadata);
//   uploadTask.on('state_changed',
//     (snapshot) => {
//       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//       // console.log('Upload is ' + progress + '% done');
//       setVideoProgress(progress);
//       switch (snapshot.state) {
//         case 'paused':
//           break;
//         case 'running':
//           break;
//       }
//     },
//     (error) => {
//       console.log("video upload error");
//     },
//     () => {
//       getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
//         setVideoUrl(downloadUrl);
//       });
//     }
//   );
// };
// Vertical links panel
// <Col xs={4} sm={4} md={4} lg={3} xl={3}>
//   <h6>Request Responses</h6>
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/request/" + showcase.gatherCode); doToastSuccess('Request URL copied to clipboard') }}><Copy size={14} /> Copy URL</p>
//   { false && <p className="small" style={{ marginBottom: 10, marginLeft: 20, cursor: 'pointer' }} onClick={() => console.log(true)}><Envelope size={14} /> Email requests</p> }
//   { showcase.publicVisibility &&
//     <div>
//       <h6 style={{ marginTop: 30 }}>Public page</h6>
//       <a href={"/p/" + showcase.id} target="_blank"><p className="small" style={{ marginTop: 10, cursor: 'pointer' }}><Eye size={14} /> Open page</p></a>
//       <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/p/" + showcase.id); doToastSuccess('Public page URL copied to clipboard') }}><Copy size={14} /> Copy URL</p>
//     </div>
//   }
//   <h6 style={{ marginTop: 30 }}>Add { showcase.showcaseType }</h6>
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setUploadModal(true)}><UploadSimple size={14} /> Upload</p>
//
//   <h6 style={{ marginTop: 30 }}>Manage showcase</h6>
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => navigate('/showcases/edit/' + showcase.id)}><PencilSimple size={14} /> Edit</p>
//
//   { showcase.publicVisibility &&
//     <div>
//       <h6 style={{ marginTop: 30 }}>Embed showcase</h6>
//       <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => { setEmbedModal(true) }}><CodeSimple size={14} /> Copy code</p>
//     </div>
//   }
// </Col>

// { videoUrl &&
//   <div style={{ marginTop: 20 }}>
//     <ReactPlayer
//         id="1"
//         url={videoUrl}
//         width="100%"
//         height="200px"
//         controls={true}
//         volume={1}
//         muted={false}
//     />
//   </div>
// }

// { posts.length > 0 && false &&
//   <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
//     {
//       posts.map((p, id) => {
//         if (filterTag === 'All' || !p.tags || p.tags.some(t => t.text === filterTag)) {
//           return (
//             <Col xs={12} sm={11} md={6} lg={6} xl={6} style={{ padding: 10 }}>
//               <PostCard post={p} collection />
//             </Col>
//           )
//         }
//       })
//     }
//   </Row>
// }
