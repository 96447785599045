import React from "react";
// import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

export default function Footer() {

  // let location = useLocation()

  return (
    <section className="footer-text" style={{ width: '100%', backgroundColor: 'transparent', color: '#111111', paddingTop: 60, paddingLeft: 30, paddingRight: 30, paddingBottom: 40, marginBottom: -100, textAlign: 'center' }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Col style={{ textAlign: 'center' }}>
          <p className="muted small">hello@nikos.ai</p>
        </Col>
      </Row>
    </section>
  )

  return (
    <section className="footer-text" style={{ width: '100%', backgroundColor: 'transparent', color: '#111111', paddingTop: 40, paddingBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: '10%', marginRight: '10%' }}>
        <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
          <h6>PRODUCT</h6>
          <p style={{ marginTop: 20 }}><a href="/">Home</a></p>
          { false && <p style={{ marginTop: -10 }}><a href="/how-it-works">How it works</a></p> }
          { false && <p style={{ marginTop: -10 }}><a href="/faqs">FAQs</a></p> }
          <p style={{ marginTop: -10 }}><a href="https://calendly.com/fundamental" target="_blank">Schedule a demo</a></p>
        </Col>
        <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
          <h6>LEGAL</h6>
          <p style={{ marginTop: 20 }}><a href="/">Privacy Policy</a></p>
          <p style={{ marginTop: -10 }}><a href="/">Terms of Service</a></p>
          <p style={{ marginTop: -10 }}><a href="/">Cookie Policy</a></p>
        </Col>
        <Col xs={6} sm={6} md={4} lg={3} xl={3} style={{ paddingTop: 20 }}>
          <h6>Contact</h6>
          <p style={{ marginTop: 20 }}><a href="mailto:hello@nikos.ai">hello@nikos.ai</a></p>
        </Col>
      </Row>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 60, textAlign: 'center' }}>
        <p className="small muted">Built by a 100% remote team. Copyright 2022, Nikos Inc.</p>
      </Row>
    </section>
  )

}


// <Col style={{ textAlign: 'right' }}>
//   <p className="muted small">
//     Twitter&nbsp;&nbsp;Discord&nbsp;&nbsp;Guide
//   </p>
// </Col>
