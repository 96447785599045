export const TEMPLATES_PROMPTS = [
  {
    id: "1",
    showcaseType: 'testimonial',
    name: 'Customer testimonials',
    description: "Collect customer testimonials to demonstrate your product's value to new customers",
    showcaseDescription: "Our customers can't stop raving about how our product has helped them achieve more success and satisfaction. From improved efficiency to better results, they're all singing our praises! What else do you need to know before you make the switch? Make sure to check out what these happy customers have to say about their experience with our product. You won't regret it!",
    icon: '/assets/icons/megaphone.svg',
    prompt: 'How has our product helped in improving your overall productivity?',
    displayType: 'masonry',
    solutions: ['Testimonials', 'Branding'],
    companies: ['SaaS', 'Consumer', 'Services', 'eCommerce'],
    personas: ['Marketing', 'Customer Success', 'Sales'],
    tips: [
      {
        id: 1,
        title: 'Which feature do you use the most and why?',
      },
      {
        id: 2,
        title: 'How has our product helped you save time and work more efficiently?',
      },
      {
        id: 3,
        title: 'Would you recommend the product to other business owners and entrepreneurs, any why?',
      },
    ],
    emailBody: "I hope you are doing well! Can you take a few minutes to share a video or text-based testimonial that we could post on our website? We want our other customers to hear directly from you. Thank you in advance for your help. Looking forward to hearing from you soon.",
  },
  {
    id: "2",
    name: 'Life at work',
    showcaseType: 'story',
    description: "Have employees share their experiences to highlight your team's culture and values",
    showcaseDescription: "Come work at __workspace__ and join a team of passionate, talented people who are changing the way products are made. We are always striving to build the best possible and we want you to be part of it. Our culture page will give you a glimpse into what life is like at __workspace__ and some of the amazing things we have been up to lately. You'll also find out about our recent events and get a chance to read some of our employee stories. So, if you're looking for an exciting, challenging workplace with awesome people, check out our open roles and apply!",
    icon: '/assets/icons/users-three.svg',
    prompt: 'What do you like the most about working at __workspace__?',
    displayType: 'masonry',
    solutions: ['Engagement', 'Branding'],
    companies: ['SaaS', 'Consumer', 'Services', 'eCommerce'],
    personas: ['HR', 'Talent acquisition', 'Employer Branding'],
    tips: [
      {
        id: 1,
        title: 'Which specific program or initiative did you like this most?',
      },
      {
        id: 2,
        title: 'What is your most memorable experience at __workspace__?',
      },
      {
        id: 3,
        title: 'Which aspects of your job do you like the most, and why?',
      },
    ],
    emailBody: "We are revamping our career site. It would be really nice if you click here and give us a testimonial about your work experience at the company",
  },
  {
    id: "3",
    name: 'Product reviews',
    showcaseType: 'review',
    description: "Collect authentic product reviews from your buyers to increase conversion rates of new buyers",
    showcaseDescription: "Explore what other customers have said about our product. Our users have shared their experiences and insights about our products and services. They not only appreciate our quality, but they also comment on the ease-of-use and convenience we offer. Hear their voice and see why we are the top choice for all your needs. And don't forget to leave a review of your own, too! We value your feedback and can't wait to hear what you have to say!",
    icon: '/assets/icons/heart.svg',
    displayType: 'masonry',
    solutions: ['Feedback', 'Reviews'],
    companies: ['Creators', 'Consumer', 'eCommerce'],
    personas: ['Marketing', 'Customer Success', 'Sales'],
    prompt: 'What do you think about our product?',
    tips: [
      {
        id: 1,
        title: 'Talk about how a specific feature you like the most and how it has helped you.',
      },
      {
        id: 2,
        title: 'What made you choose our product and how long have you been using it?',
      },
      {
        id: 3,
        title: 'How does the product compare to others you have used?',
      },
    ],
    emailBody: "Thank you for giving us an opportunity to be a part of your work life. We hope we are able to delight you with our product. We would love for you to share your review of our product with us so we can share it with other customers.",
  },
  {
    id: "4",
    name: 'Interview feedback',
    showcaseType: 'feedback',
    description: "Gather feedback from interview candidates during your recruitment process",
    showcaseDescription: "Our recruitment process has received a lot of positive feedback from our candidates! They have expressed their satisfaction with the ease and convenience of applying for our job openings, as well as their appreciation for the speed and efficiency with which our recruitment team has addressed their queries. We take pride in the comprehensive approach we take to each applicant's journey, providing them with the resources and support they need to make an informed decision about joining our team. So, don't just take our word for it - check out what our candidates have to say and see if you'd like to join them in being a part of the success story here at __workspace__. You won't be disappointed!",
    icon: '/assets/icons/user-gear.svg',
    displayType: 'masonry',
    solutions: ['Feedback', 'Reviews'],
    companies: ['Recruiting','SaaS','Services','eCommerce','Consumer'],
    personas: ['Talent Acquisition', 'HR'],
    prompt: 'On a scale of 1 to 5, how would you rate your overall recruitment experience?',
    tips: [
      {
        id: 1,
        title: 'Talk about a noteworthy and memorable conversation',
      },
      {
        id: 2,
        title: 'How satisfied have you been with the speed and quality of our recruitment process? Did our team deliver a seamless experience?',
      },
      {
        id: 3,
        title: 'How would you rate the communication between our team and yourself throughout the recruitment process? Was it timely, responsive and helpful?',
      },
    ],
    emailBody: "Hope you are keeping well! Can you take a few minutes to share your love, comments, concerns or suggestions with us here about our recruiting process. It would really mean a lot in helping us build a more pleasant experience for other candidates.",
  },
  {
    id: "5",
    name: 'Non-profit stories',
    showcaseType: 'story',
    description: "Gather stories from your community and team demonstrating their support for your cause.",
    showcaseDescription: "At our organization, we are so proud to have the ongoing support of our incredible supporters. From generous donations and volunteering their time, to sharing stories and experiences, they demonstrate their unwavering commitment to our cause. We value each and every one of them, and we'd like to take this opportunity to shine a light on some of their remarkable stories. We hope their stories will inspire you to join our cause as well!",
    icon: '/assets/icons/lifebuoy.svg',
    displayType: 'masonry',
    solutions: ['Engagement'],
    companies: ['Non-profit'],
    personas: ['Leaders', 'HR'],
    prompt: 'How has your involvement with our cause impacted or changed you?',
    tips: [
      {
        id: 1,
        title: "What inspired you to join us and become an active participant?",
      },
      {
        id: 2,
        title: 'What do you believe is the biggest accomplish of our cause?',
      },
      {
        id: 3,
        title: "Share one noteworthy story from your journey",
      },
    ],
    emailBody: "It's so inspiring to see the way everyone has come together to show their passion and commitment to our cause. We know you all have valuable stories that demonstrate your support for it and we would love if you could share them with us.",
  },
  {
    id: "6",
    name: 'Organizational initiatives',
    showcaseType: 'story',
    description: "Showcase support for your initiatives, be it employee volunteering, DEI, or a culture week.",
    showcaseDescription: "Employees are enthusiastically embracing and engaging in initiatives within __workspace__. The impact our employees have made has been tremendous and it is a source of great pride for us all. Not only does this help build a sense of community, but also strengthens the bond between our employees and the organization.",
    icon: '/assets/icons/handshake.svg',
    displayType: 'masonry',
    solutions: ['Engagement','Branding'],
    companies: ['SaaS','Services','eCommerce','Consumer','Recruiting'],
    personas: ['Leaders', 'HR','Employer Branding','Talent Management'],
    prompt: 'How was your experience participating in our recent event?',
    tips: [
      {
        id: 1,
        title: "What motivated you to participate? Tell us more about why it was so important to you",
      },
      {
        id: 2,
        title: 'How did you feel when the experience was over? Did it influence the way you think or behave now?',
      },
      {
        id: 3,
        title: "What made this experience stand out from all the others you've had in the past?",
      },
    ],
    emailBody: "We are excited to announce we are building a wall of love for our initiatives at  __workspace__! This wall is a way for us to celebrate the hard work, commitment and effort that everyone in the team has put in. We'd love to have you contribute your story.",
  },
  {
    id: "1000",
    name: 'Start from scratch',
    showcaseType: 'post',
    description: "Create a showcase from a blank template",
    showcaseDescription: "",
    icon: '/assets/icons/wall.svg',
    displayType: 'masonry',
    solutions: [],
    companies: [],
    personas: [],
    prompt: '',
    tips: [
      {
        id: 1,
        title: '',
      },
      {
        id: 2,
        title: '',
      },
      {
        id: 3,
        title: '',
      },
    ],
    emailBody: "",
  },
];

// {
//   id: "4",
//   name: 'Events at work',
//   description: "Collect photos and videos from events at work you want to remember and share",
//   icon: '/assets/icons/lightning.svg',
//   categories: ['Events', 'Talent acquisition'],
//   prompt: 'Upload your favorite stories, photos or videos',
//   tips: [
//     {
//       id: 1,
//       title: 'Talk about a fun, exciting, or memorable moment',
//     },
//     {
//       id: 2,
//       title: 'Show us how you decorated your workspace or common areas',
//     },
//     {
//       id: 3,
//       title: 'How was your experience special this year with current co-workers?',
//     },
//   ],
//   emailBody: "Share your photos and videos with the rest of us so we can showcase them on our wall.",
// },


// {
//   id: 1,
//   name: 'Life at work',
//   category: 'HR',
//   coverUrl: 'https://i2.wp.com/hr-gazette.com/wp-content/uploads/2018/10/bigstock-Recruitment-Concept-Idea-Of-C-250362193.jpg?w=1600&ssl=1',
//   prompt: 'What do you like the most about working here at the company?',
//   tips: [
//     {
//       id: 1,
//       title: 'Which specific corporate initiative have you liked the most and why?',
//     },
//     {
//       id: 2,
//       title: 'Tell us about your most memorable experience',
//     },
//     {
//       id: 3,
//       title: 'Which aspects of your job do you like the most and why?',
//     },
//   ],
// },
