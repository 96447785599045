import React from "react"
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequireAuth } from "../../use-require-auth.js";
import { Row, Col } from 'react-bootstrap';
import Logo from "./Logo.js";
import { useMediaQuery } from "../../shared-functions.js";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent } from 'react-pro-sidebar';
import { Gear, ChalkboardSimple, Wall, SquaresFour, NoteBlank, UsersFour } from "phosphor-react";

export default function Sidebar(props) {

  // const auth = useRequireAuth();
  const location = useLocation();
  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 960px)')

  if (location.pathname === '/notfound' ||
      location.pathname === '/terms' ||
      location.pathname === '/privacy') {
    return (
      <div></div>
    )
  }

  if (location.pathname !== '/login' &&
      location.pathname !== '/signup' &&
      location.pathname !== '/forgotpassword' &&
      !location.pathname.startsWith('/request') &&
      !location.pathname.startsWith('/p/') &&
      !location.pathname.startsWith('/onboarding') &&
      !location.pathname.startsWith('/showcase/') &&
      !location.pathname.startsWith('/post/') &&
      !location.pathname.startsWith('/workspaceinvitation')
    ) {
    return (
      <Col xs="auto" style={{ height: '97vh', padding: 0 }}>
        <ProSidebar collapsed={isPageWide ? false : true} width="240px">
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem active={location.pathname === '/' ? true : false} icon={<ChalkboardSimple size={20} weight="bold" />} onClick={() => navigate("/")}>Dashboard</MenuItem>
              <MenuItem active={location.pathname === '/showcases' || location.pathname.startsWith('/showcase') ? true : false} icon={<SquaresFour size={20} weight="bold" />} onClick={() => navigate("/showcases")}>Showcases</MenuItem>
              <MenuItem active={location.pathname === '/members' ? true : false} icon={<UsersFour size={20} weight="bold" />} onClick={() => navigate("/members")}>Members</MenuItem>
              <MenuItem active={location.pathname === '/settings' ? true : false} icon={<Gear size={20} weight="bold" />} onClick={() => navigate("/settings")}>Settings</MenuItem>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </Col>
    )
  } else {
    return (
      <div></div>
    )
  }


}

//               <MenuItem active={location.pathname === '/collections' || location.pathname.startsWith('/collection/') ? true : false} icon={<SquaresFour size={20} weight="bold" />} onClick={() => navigate("/collections")}>Collections</MenuItem>
// <SubMenu title="Fundraising" icon={<HouseSimple size={20} />} defaultOpen={true}>
//   <MenuItem active={location.pathname === '/dashboard/crowdfunds' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/crowdfunds")}>Crowdfunds</MenuItem>
//   <MenuItem active={location.pathname === '/dashboard/tokens' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/tokens")}>Tokens</MenuItem>
//   <MenuItem active={location.pathname === '/dashboard/donations' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/donations")}>Donations</MenuItem>
// </SubMenu>
// <SubMenu title="Collectibles" icon={<HouseSimple size={20} />} defaultOpen={true}>
//   <MenuItem active={location.pathname === '/dashboard/nfts' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/crowdfund")}>NFTs</MenuItem>
// </SubMenu>
// <SubMenu title="Events" icon={<HouseSimple size={20} />} defaultOpen={true}>
//   <MenuItem active={location.pathname === '/dashboard/tickets' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/tickets")}>Tickets</MenuItem>
// </SubMenu>
// <SubMenu title="Tools" icon={<HouseSimple size={20} />} defaultOpen={true}>
//   <MenuItem active={location.pathname === '/dashboard/tipjars' ? true : false} icon={<HouseSimple size={20} />} onClick={() => navigate("/dashboard/tipjars")}>Tip Jars</MenuItem>
// </SubMenu>
