import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get_first_name, useMediaQuery, numberWithCommas } from './shared-functions.js';
import { db } from "./use-firebase.js";
import { useRequireAuth } from "./use-require-auth.js";
import { collection, where, query, getDocs, orderBy, limit } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Image, Modal, Form, Spinner, Card, Table } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { Copy, Eye, Megaphone, UsersThree, Heart, ArrowRight, PencilSimple } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';

export default function Showcases() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 960px)');
  const [showcases, setShowcases] = useState([]);
  const [postsInShowcase, setPostsInShowcase] = useState([]);

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {

      // find posts
      getDocs(query(collection(db, "posts"), where("workspaceId", "==", auth.workspace.id), orderBy("createdTs", "desc") )).then((querySnapshot) => {
        let _latestPosts = []
        let _postsInShowcase = []
        querySnapshot.forEach((doc) => {
          if (_latestPosts.length < 3) {
            _latestPosts.push(doc.data());
          }
          let found = false;
          _postsInShowcase.forEach((p, i) => {
            if (p.showcaseId === doc.data().showcaseId) {
              p.count += 1;
              found = true;
            }
          });
          if (!found) {
            _postsInShowcase.push({ showcaseId: doc.data().showcaseId, count: 1 });
          }
        });
        setPostsInShowcase(_postsInShowcase);
      });

      // find showcases
      getDocs(query(collection(db, "showcases"), where("workspaceId", "==", auth.workspace.id), orderBy("createdTs", "desc"))).then((querySnapshot) => {
        let _showcases = []
        querySnapshot.forEach((doc) => {
          _showcases.push(doc.data())
        });
        setShowcases(_showcases);
      });

    }
    window.scrollTo(0,0);
  }, [auth]);

  const getPostCount = (_id) => {
    let count = 0;
    postsInShowcase.forEach((p, i) => {
      if (p.showcaseId === _id) {
        count = p.count;
      }
    });
    return count;
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-right",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-right",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };


  if (!auth || !auth.user || !auth.workspace) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <h3>My showcases</h3>
            <Button variant="primary" style={{ marginLeft: 20 }} onClick={() => navigate('/showcases/new')}>+ Create new</Button>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 10, marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              { showcases.length === 0 &&
                <p style={{ marginTop: 10 }}>None yet</p>
              }
              { showcases.length > 0 &&
                <Table borderless responsive striped variant="light" style={{ backgroundColor: '#FFFFFF' }}>
                  <thead>
                    <tr style={{ fontFamily: 'SF Bold' }}>
                      <th>Name</th>
                      <th>Posts</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      showcases.map((c, id) => {
                        return (
                          <tr key={id}>
                            <td>
                              <Image src={c.icon} style={{ height: 18, width: 18, marginRight: 16 }} />
                              <a href={"/showcase/" + c.id}>{ c.name }</a>{ !c.publicVisibility && <span style={{ marginLeft: 10 }} className="small muted">hidden</span> }
                            </td>
                            <td>{ getPostCount(c.id) }</td>
                            <td>
                              { c.publicVisibility && <a href={"/p/" + c.id} target="_blank"><Eye size={16} style={{ marginRight: 10, cursor: 'pointer' }} /></a> }
                              { c.publicVisibility && <Copy size={16} style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/p/" + c.id); doToastSuccess('Public page URL copied to clipboard') }} /> }
                              <PencilSimple size={16} style={{ cursor: 'pointer' }} onClick={() => { navigate('/showcases/edit/' + c.id) }} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              }
            </Col>
          </Row>
        </Col>
      </Row>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  )

}
