import React, { useState, useEffect } from 'react';
import { useAuth } from "./use-firebase.js";
import { useNavigate } from 'react-router-dom';
import Logo from "./components/common/Logo.js"
import { Row, Col, Card, Image, Button, Form } from "react-bootstrap";

export default function Signup(props) {

  const auth = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (auth.user) {
      navigate('/');
    }
    window.scrollTo(0,0)
  }, [auth]);

  const handleValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        // saveProfile();
      }
      setValidated(true);
    }
  };

  return (
    <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 60, marginBottom: 40, height: '80vh' }}>
      <Col xs={12} sm={12} md={8} lg={6} xl={5}>
        <Card style={{ border: '1px solid #eeeeee', padding: 40 }}>
          <h3>Sign up</h3>
          <Image src={"/assets/buttons/signupwithgoogle.png"} style={{ width: 200, marginTop: 20, cursor: 'pointer', textAlign: 'center' }} onClick={() => auth.googleAuthenticate()} />
          <h5 style={{ marginTop: 20 }}>or signup with email</h5>
          <Form noValidate validated={validated} onSubmit={handleValidation} style={{ marginTop: 10 }}>
            <Form.Label style={{ marginTop: 10, marginBottom: 5 }}>
              <h6>Name</h6>
            </Form.Label>
            <Form.Group controlId="name">
              <Form.Control required as="input" type="text" minLength="3" maxLength="96" value={name} onChange={(e) => setName(e.target.value)} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter a valid name
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
              <h6>Email</h6>
            </Form.Label>
            <Form.Group controlId="email">
              <Form.Control required as="input" type="email" minLength="3" maxLength="96" value={email} onChange={(e) => setEmail(e.target.value)} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter a valid email
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
              <h6>Password</h6>
            </Form.Label>
            <Form.Group controlId="password">
              <Form.Control required as="input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            { error && error.code === 'auth/email-already-exists' &&
              <p className="text-danger small" style={{ marginTop: 20 }}>Email exists. Try again.</p>
            }
            { error && error.code !== 'auth/email-already-exists' &&
              <p className="text-danger small" style={{ marginTop: 20 }}>Invalid credentials. Try again.</p>
            }
            <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Button variant="primary" onClick={() => { auth.signup(email, password, name).then(response => { if (response.message != null) { setError(response); setEmail(""); setPassword(""); } }) }}>Create account</Button>
            </Row>
          </Form>
          <p className="small" style={{ marginTop: 10 }}>Have an account?&nbsp;&nbsp;<a href="/login">Login</a></p>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <small>By creating and logging into an account you agree to this <a href="https://firebasestorage.googleapis.com/v0/b/nikos-content.appspot.com/o/policies%2FNikos%20Privacy%20Policy.pdf?alt=media&token=220ec35e-2fe6-4ff8-9b9a-48ad7b686c6c" target="_blank">privacy policy</a> and these <a href="https://firebasestorage.googleapis.com/v0/b/nikos-content.appspot.com/o/policies%2FNikos%20Terms%20Of%20Use.pdf?alt=media&token=28d62774-40b7-45cb-968a-8cba1f8f0d2e" target="_blank">terms of use</a></small>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
