import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEmail, useMediaQuery } from './shared-functions.js';
import { STYLE_SELECT } from './shared-styles.js';
import { db } from "./use-firebase.js";
import { useRequireAuth } from "./use-require-auth.js";
import { collection, getDocs, where, query, doc, updateDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Table, Spinner, Image, Form, Badge, Card } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { ArrowLeft } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPlayer from 'react-player';
import Moment from 'react-moment';
import Select from 'react-select';
import { WithContext as ReactTags } from 'react-tag-input';
import { YouTubeEmbed, TwitterEmbed, LinkedInEmbed } from 'react-social-media-embed';
import StarRatings from 'react-star-ratings';
import MuxPlayer from '@mux/mux-player-react';

export default function Post() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const props = useParams();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [post, setPost] = useState(null);
  const [localMuxPlaybackId, setLocalMuxPlaybackId] = useState(null);
  const [comment, setComment] = useState(null);
  const [approved, setApproved] = useState(false);
  const [rating, setRating] = useState(0);
  const [publicVisibility, setPublicVisibility] = useState(false);

  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const KeyCodes = { comma: 188, enter: 13 }
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {
      // get post
      if (props === undefined || props.id === undefined) {
        navigate('/notfound');
      } else {
        getDocs(query(collection(db, "posts"), where("workspaceId", "==", auth.workspace.id), where("id", "==", props.id))).then((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            querySnapshot.forEach((_doc) => {
              setPost(_doc.data());
              setComment(_doc.data().comment);
              setRating(_doc.data().rating || 0);
              setApproved(_doc.data().approved || false);
              setPublicVisibility(_doc.data().publicVisibility || false);
              setTags(_doc.data().tags || []);
              if (_doc.data().muxId) {
                if (_doc.data().muxPlaybackId) {
                  setLocalMuxPlaybackId(_doc.data().muxPlaybackId);
                } else {
                  getMuxPlaybackId(_doc.data().muxId);
                }
              }
            });
          } else {
            navigate('/notfound');
          }
        });
      }
    }
    window.scrollTo(0,0);
  }, [auth]);

  const getMuxPlaybackId = (_id) => {
    getDocs(query(collection(db, "muxvideos"), where("id", "==", _id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().status === 'ready' && doc.data().asset && doc.data().asset.playback_ids) {
          setLocalMuxPlaybackId(doc.data().asset.playback_ids[0].id);
        }
      });
    });
  };

  const saveWallsAndTags = () => {
    getDocs(query(collection(db, "posts"), where("workspaceId", "==", auth.workspace.id), where("id", "==", props.id))).then((querySnapshot) => {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((_doc) => {
          updateDoc(doc(db, "posts", _doc.id), { comment: comment ? comment : null,
                                                 tags: tags,
                                                 rating: rating,
                                                 approved: approved,
                                                 publicVisibility: publicVisibility
                                               }).then(() => {
            doToastSuccess("Changes saved!");
          });
        });
      }
    });
  };

  const handleDelete = i => {
    let _tags = tags.filter((tag, index) => index !== i)
    setTags(_tags);
  };

  const handleAddition = tag => {
    let _tag = { id: tag.id, text: tag.text.replace(/#/g,'') }
    let newTags = [...tags, _tag];
    setTags(newTags)
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const handleTagClick = index => {
    // console.log('The tag at index ' + index + ' was clicked');
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-right",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-right",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (post === null) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col xs={12} sm={12} md={11} lg={11} xl={11}>
          <p className="small muted"><a href={"/showcase/" + post.showcaseId }><ArrowLeft /> Back to showcase</a></p>
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={10} md={9} lg={8} xl={7}>
              { localMuxPlaybackId &&
                <div>
                  <MuxPlayer
                    streamType="on-demand"
                    playbackId={localMuxPlaybackId}
                    primaryColor="#CCCCCC"
                    width="100%"
                    metadata={{
                      video_id: post.muxId,
                      video_title: post.name
                    }}
                  />
                </div>
              }
              { !localMuxPlaybackId &&
                <Card style={{ marginTop: 20, backgroundColor: '#eeeeee', height: 300, cursor: 'pointer' }}>
                  <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                    <h5 className="muted">No video</h5>
                  </Row>
                </Card>
              }
              { post.type === 'video' && post.url && post.url.length > 0 && false &&
                <div style={{ marginTop: 20 }}>
                  <ReactPlayer
                    id={"id-" + post.id}
                    url={post.url}
                    width="100%"
                    height="100%"
                    controls
                    volume={1}
                  />
                </div>
              }
              { post.type === 'video' && (!post.url || post.url.length === 0) && false &&
                <Card style={{ marginTop: 20, backgroundColor: '#eeeeee', height: 300, cursor: 'pointer' }}>
                  <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                    <h5 className="muted">No video</h5>
                  </Row>
                </Card>
              }
            </Col>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginTop: 20, marginRight: 0 }}>
            <Col xs={12} sm={12} md={11} lg={11} xl={10}>
              <Form style={{ marginTop: 5 }}>
                <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
                  <Col xs={12} sm={11} md={6} lg={6} xl={6} style={{ marginBottom: 20 }}>
                    <Form.Label style={{ marginBottom: 5 }}>
                      <h6>Rating</h6>
                    </Form.Label>
                    <div>
                      <StarRatings
                        rating={rating}
                        starRatedColor="orange"
                        changeRating={(val) => setRating(val)}
                        numberOfStars={5}
                        name='rating'
                        starDimension={20}
                        starSpacing={1}
                        style={{ width: 100 }}
                      />
                    </div>
                    <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                      <h6>Comment</h6>
                    </Form.Label>
                    <Form.Group controlId="name">
                      <Form.Control as="textarea" type="text" maxLength="500" rows={3} value={comment} onChange={(e) => setComment(e.target.value)} />
                    </Form.Group>
                    <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                      <h6>Created by</h6>
                    </Form.Label>
                    <h5 style={{ marginTop: 5 }}>{ post.name }</h5>
                    <p className="small muted">{ post.jobTitle }</p>
                    <p className="small muted">on <Moment format="ddd MMM DD, YYYY">{ post.createdTs }</Moment></p>
                  </Col>
                  <Col xs={12} sm={11} md={6} lg={6} xl={6}>
                    <h6 style={{ marginBottom: 5 }}>Tags</h6>
                    <div style={{ backgroundColor: 'transparent' }}>
                      <ReactTags
                        tags={tags}
                        suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleDrag={handleDrag}
                        handleTagClick={handleTagClick}
                        inline={true}
                        placeholder={"+ Add tags"}
                        inputFieldPosition="inline"
                        autofocus={false}
                        autocomplete
                      />
                    </div>
                    <h6 style={{ marginTop: 20, marginBottom: 0 }}>Moderation</h6>
                    <p class="small muted">By checking of this box you approve this post for viewing on public pages if it were made publicly visible.</p>
                    <Form.Check type="checkbox" id="moderation-switch" label="Approve" checked={approved} onChange={(e) => { setApproved(e.target.checked); }} />
                    <h6 style={{ marginTop: 20, marginBottom: 0 }}>Public visibility</h6>
                    <p class="small muted">By checking of this box you make this post publicly visible on public pages of the showcase it is on.</p>
                    <Form.Check type="checkbox" id="visibility-switch" label="Public" checked={publicVisibility} onChange={(e) => { setPublicVisibility(e.target.checked); }} />
                  </Col>
                  <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
                    <Button variant="secondary" onClick={() => saveWallsAndTags()}>Save changes</Button>
                  </Row>
                </Row>
              </Form>

            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  )

}

// { post.type === 'photo' &&
//   <Image src={ post.url } style={{ marginTop: 20, objectFit: 'contain', width: '100%', maxHeight: 400 }} />
// }
// { post.type === 'url' && post.source === 'youtube' && post.url && post.url.length > 0 &&
//   <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
//     <YouTubeEmbed url={post.url} width="100%" height={'360px'} />
//   </div>
// }
// { post.type === 'url' && post.source === 'twitter' && post.url && post.url.length > 0 &&
//   <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
//     <TwitterEmbed url={post.url} width="100%" />
//   </div>
// }
// { post.type === 'url' && post.source === 'linkedin' && post.url && post.url.length > 0 &&
//   <div style={{ display: 'flex', justifyContent: 'center', padding: 5, border: '1px solid #cccccc', borderRadius: 10, marginTop: 20 }}>
//     <LinkedInEmbed url={post.url} width="100%" />
//   </div>
// }
// { post.type === 'quote' &&
//   <h3 style={{ marginTop: 20, marginBottom: 20 }}>"{ post.quote }"</h3>
// }

// <h6 style={{ marginTop: 20, marginBottom: 10 }}>Walls</h6>
// <Select
//   isSearchable
//   isMulti
//   options={walls}
//   defaultValue={postWalls}
//   onChange={(val) => setPostWalls(val)}
//   getOptionValue={option => option.id}
//   getOptionLabel={option => option.name}
//   placeholder="Choose walls"
//   styles={STYLE_SELECT}
// />
