import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, generateRandomString, convertToSlug } from './shared-functions.js';
import { TEMPLATES_PROMPTS } from './shared-templates.js';
import { db } from "./use-firebase.js";
import { useRequireAuth } from "./use-require-auth.js";
import { collection, addDoc, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { Row, Col, Button, Form, Card, Badge, Image } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import {  } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ShowcasesEdit() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const props = useParams();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [validated, setValidated] = useState(false);
  const [showcase, setShowcase] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [icon, setIcon] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [tips, setTips] = useState([]);
  const [emailBody, setEmailBody] = useState(null);
  const [templateChosen, setTemplateChosen] = useState(null);
  const [videoContent, setVideoContent] = useState(true);
  const [photoContent, setPhotoContent] = useState(true);
  const [quoteContent, setQuoteContent] = useState(true);
  const [extraName, setExtraName] = useState(true);
  const [extraJobTitle, setExtraJobTitle] = useState(true);
  const [extraLinkedin, setExtraLinkedin] = useState(true);
  const [extraTwitter, setExtraTwitter] = useState(true);
  const [publicVisibility, setPublicVisibility] = useState(true);

  useEffect(() => {
    if (auth && auth.user && auth.workspace && props && props.id) {
      getDocs(query(collection(db, "showcases"), where("workspaceId", "==", auth.workspace.id), where("id", "==", props.id) )).then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            setShowcase(doc.data());
            setName(doc.data().name);
            setDescription(doc.data().description || null);
            setPrompt(doc.data().prompt || null);
            setTips(doc.data().tips || []);
            setEmailBody(doc.data().emailBody || null);
            setVideoContent(doc.data().videoContent || false);
            setPhotoContent(doc.data().photoContent || false);
            setQuoteContent(doc.data().quoteContent || false);
            setExtraName(doc.data().extraName || false);
            setExtraJobTitle(doc.data().extraJobTitle || false);
            setExtraLinkedin(doc.data().extraLinkedin || false);
            setExtraTwitter(doc.data().extraTwitter || false);
            setPublicVisibility(doc.data().publicVisibility || false);
          });
        } else {
          navigate('/notfound')
        }
      });
    }
    window.scrollTo(0,0);
  }, [auth]);

  const handleEditValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        handleEdit();
      }
      setValidated(true);
    }
  };

  const handleEdit = () => {
    getDocs(query(collection(db, "showcases"), where("workspaceId", "==", auth.workspace.id), where("id", "==", props.id) )).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        let _showcase = _doc.data();
        updateDoc(doc(db, "showcases", _doc.id), {
          name: name,
          description: description,
          prompt: prompt,
          tips: tips,
          emailBody: emailBody,
          videoContent: videoContent,
          photoContent: photoContent,
          quoteContent: quoteContent,
          extraName: extraName,
          extraJobTitle: extraJobTitle,
          extraLinkedin: extraLinkedin,
          extraTwitter: extraTwitter,
          publicVisibility: publicVisibility,
        }).then(() => {
          doToastSuccess("Edits saved!");
          navigate('/showcase/' + showcase.id);
        });
      });
    });
  };

  const editTips = (_id, _text) => {
    let _tips = []
    tips.forEach((t, i) => {
      if (t.id === _id) {
        _tips.push({ id: t.id, title: _text });
      } else {
        _tips.push(t);
      }
    });
    setTips(_tips);
  }

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-right",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-right",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <h3>Edit showcase</h3>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 40, marginLeft: 0, marginRight: 0, width: '100%' }}>
            <Col xs={12} sm={11} md={10} lg={8} xl={8}>
              <Form noValidate validated={validated} onSubmit={handleEditValidation}>
                <Form.Label style={{ marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Name</h6><p className="small muted">A descriptive name for your showcase</p>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Customer testimonials" value={name} onChange={(e) => setName(e.target.value)} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid name 3 to 96 characters in length
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Description</h6><p className="small muted">Describe the types of content and their benefits to visitors to the showcase. p.s. This will be displayed on the public page of the showcase.</p>
                </Form.Label>
                <Form.Group controlId="description">
                  <Form.Control required required as="textarea" minLength="6" maxLength="1000" rows={5} placeholder="e.g. Videos, photos and quotes share by some of our users sharing their experience using our products." value={description} onChange={(e) => setDescription(e.target.value)} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid one liner 6 to 500 characters in length
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Prompt</h6><p className="small muted">What content would you like your team, customers, fans, and friends to share with you for this showcase?</p>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control required as="textarea" type="text" minLength="3" maxLength="280" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid name 3 to 280 characters in length
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Tips</h6><p className="small muted">Include up to three tips that will help your team, customers, fans, and friends easily create the content you'd like</p>
                </Form.Label>
                {
                  tips.map((t) => {
                    return (
                      <Form.Group controlId="name" style={{ marginBottom: 10 }}>
                        <Form.Control as="textarea" type="text" rows={2} minLength="3" maxLength="500" value={t.title} onChange={(e) => editTips(t.id, e.target.value)} />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Enter a valid name 3 to 280 characters in length
                        </Form.Control.Feedback>
                      </Form.Group>
                    )
                  })
                }
                { false &&
                  <div>
                    <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                      <h6 style={{ marginBottom: 0 }}>Email body</h6><p className="small muted">Enter text you would like included in the request emails you send to your team, customers, fans and friends</p>
                    </Form.Label>
                    <Form.Group controlId="name">
                      <Form.Control as="textarea" type="text" maxLength="500" value={emailBody} onChange={(e) => setEmailBody(e.target.value)} />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Enter a valid email up to 500 characters in length
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                }
                { false &&
                  <div>
                    <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                      <h6 style={{ marginBottom: 0 }}>Content types</h6><p className="small muted">What content types would you like to request for this showcase? Choose atleast one.</p>
                    </Form.Label>
                    <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                      <Form.Check type={'checkbox'} id="content-video" checked={videoContent} onChange={(e) => setVideoContent(e.target.checked)} label={"Video"} />
                      <Form.Check type={'checkbox'} id="content-photos" checked={photoContent} onChange={(e) => setPhotoContent(e.target.checked)} label={"Photo"} style={{ marginLeft: 20 }} />
                      <Form.Check type={'checkbox'} id="content-quotes" checked={quoteContent} onChange={(e) => setQuoteContent(e.target.checked)} label={"Quotes"} style={{ marginLeft: 20 }} />
                    </Row>
                  </div>
                }
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Extra fields</h6><p className="small muted">Choose extra info you would like to collect with your responses</p>
                </Form.Label>
                <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                <Form.Check type={'checkbox'} id="extra-name" checked={extraName} onChange={(e) => setExtraName(e.target.checked)} label={"Name"} style={{ marginRight: 20 }} />
                <Form.Check type={'checkbox'} id="extra-jobtitle" checked={extraJobTitle} onChange={(e) => setExtraJobTitle(e.target.checked)} label={"Job title"} style={{ marginRight: 20 }} />
                <Form.Check type={'checkbox'} id="extra-linkedin" checked={extraLinkedin} onChange={(e) => setExtraLinkedin(e.target.checked)} label={"LinkedIn handle"} style={{ marginRight: 20 }} />
                <Form.Check type={'checkbox'} id="extra-linkedin" checked={extraTwitter} onChange={(e) => setExtraTwitter(e.target.checked)} label={"Twitter handle"} style={{ marginRight: 20 }} />
                </Row>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Public visibility</h6><p className="small muted">Choose whether you would like this showcase page to be publicly viewable. Non-public showcases can only be accessed by your workspace members when they are logged in. This can be changed once the showcase is created.</p>
                </Form.Label>
                <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                  <Form.Check type={'checkbox'} id="public-visibility" checked={publicVisibility} onChange={(e) => setPublicVisibility(e.target.checked)} label={"Public"} />
                </Row>
                <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                  <Button variant="tertiary" onClick={() => navigate('/showcase/' + showcase.id)}>Cancel</Button>
                  <Button variant="secondary" type="submit" disabled={ (videoContent || photoContent || quoteContent) ? false : true }>Save changes</Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  );

}
