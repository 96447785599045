import React, { useState, useEffect } from 'react';
import { useAuth } from "./use-firebase.js";
import { useNavigate } from 'react-router-dom';
import Logo from "./components/common/Logo.js"
import { Row, Col, Card, Image, Button, Form } from "react-bootstrap";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ForgotPassword(props) {

  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null)

  useEffect(() => {
    if (auth.user) {
      navigate('/');
    }
    window.scrollTo(0,0)
  }, [auth]);

  const handleValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        // saveProfile();
      }
      setValidated(true);
    }
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-right",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-right",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  return (
    <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 60, marginBottom: 40, height: '80vh' }}>
      <Col xs={12} sm={12} md={8} lg={6} xl={5}>
        <Card style={{ border: '1px solid #eeeeee', padding: 40 }}>
          <h3>Reset password</h3>
          <Form noValidate validated={validated} onSubmit={handleValidation} style={{ marginTop: 10 }}>
            <Form.Label style={{ marginTop: 10, marginBottom: 5 }}>
              <h6>Email</h6>
            </Form.Label>
            <Form.Group controlId="email">
              <Form.Control required as="input" type="email" minLength="3" maxLength="96" value={email} onChange={(e) => setEmail(e.target.value)} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter a valid email
              </Form.Control.Feedback>
            </Form.Group>
            { error && error.code === "auth/missing-email" &&
              <p className="text-danger small" style={{ marginTop: 20 }}>Missing email. Try again</p>
            }
            { error && error.code !== "auth/missing-email" &&
              <p className="text-danger small" style={{ marginTop: 20 }}>Invalid email. Try again</p>
            }
            <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Button variant="primary" onClick={() => { auth.forgotPassword(email).then(response => { if (response && response.message != null) { setError(response); setEmail(""); } else { doToastSuccess('Password reset email sent!'); navigate('/login'); } }) }}>Send</Button>
            </Row>
          </Form>
          <p className="small" style={{ marginTop: 10 }}>Remember password?&nbsp;&nbsp;<a href="/login">Login</a></p>
        </Card>
      </Col>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  );
}
