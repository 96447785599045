import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from "../../use-firebase.js"
import { useMediaQuery, getSubDomain, get_first_name } from "../../shared-functions.js"
import Logo from "./Logo.js";
import { Navbar, Nav, NavDropdown, Badge } from 'react-bootstrap';
import { List, ArrowUpRight } from "phosphor-react";

export default function TopNavbar(props) {

  const location = useLocation();
  const auth = useAuth();
  let isPageWide = useMediaQuery('(min-width: 600px)');
  // let subdomain = getSubDomain(window.location.host);

  if (location.pathname.startsWith('/request') ||
      location.pathname.startsWith('/p/')
    ) {
    return (
      <div></div>
    )
  }

  if (location.pathname.startsWith('/onboarding') ||
      location.pathname.startsWith('/workspaceinvitation')
    ) {
    return (
      <Navbar justify="true" expand="md" fixed="top" variant="light" style={{ backgroundColor: '#FFFFFF', paddingTop: 5 }}>
        <Logo />
      </Navbar>
    )
  }

  if (!auth.user) {
    return (
      <Navbar justify="true" expand="md" fixed="top" variant="light" style={{ backgroundColor: '#FFFFFF', paddingTop: 5 }}>
        <Logo />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={location.pathname} className="ml-auto">
            <Nav.Link href="/login"><h6>Login</h6></Nav.Link>
            <Nav.Link href="/signup"><h6>Sign up</h6></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  } else {
    return (
      <Navbar justify="true" expand="md" fixed="top" variant="light" style={{ backgroundColor: '#FFFFFF', paddingTop: 5, paddingLeft: 20, paddingRight: 20 }}>
        <Logo />
        <Navbar.Toggle aria-controls="basic-navbar-nav"><List style={{ color: '#111111' }} /></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <NavDropdown alignright="true" className="ml-auto" id="dropdown-button-drop-start" drop="down" title={get_first_name(auth.user.name)} >
            <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item style={{ color: 'red' }} onClick={() => { auth.signout() }}>Signout</NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    )
  };


}


// return (
//   <Navbar justify="true" fixed="top" variant="light" style={{ textAlign: 'center', paddingTop: 5, paddingBottom: 10, backgroundColor: '#13103C' }}>
//     <Logo />
//     { (location.pathname === '/builder' || location.pathname === '/settings') &&
//       <>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav activeKey={location.pathname} className="m-auto">
//             <Nav.Link href="/builder"><h6>Builder</h6></Nav.Link>
//             <Nav.Link href="/settings"><h6>Settings</h6></Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </>
//     }
//   </Navbar>
// );

// if (location.pathname === '/') {
//   return (
//     <Navbar justify expand="md" fixed="top" variant="light" style={{ backgroundColor: '#050A30', paddingTop: 8 }}>
//       <Logo />
//     </Navbar>
//   )
// }
//
// if (location.pathname !== '/' &&
//     location.pathname !== '/login' &&
//     location.pathname !== '/signup'  &&
//     location.pathname !== '/notfound'  &&
//     location.pathname !== '/builder'  &&
//     location.pathname !== '/settings' &&
//     location.pathname !== '/onboarding' &&
//     location.pathname !== '/connectwallet' &&
//     location.pathname !== '/home' &&
//     !location.pathname.startsWith('/dashboard')
//   ) {
//   return (
//     <div></div>
//   )
// }
