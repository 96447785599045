import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { getSubDomain } from "./shared-functions.js";

import { Container, Row, Col } from 'react-bootstrap';

import TopNavbar from "./components/common/TopNavbar.js"
import Sidebar from "./components/common/Sidebar.js";
import Footer from "./components/common/Footer.js";
// import Privacy from "./pages/legal/Privacy.js"
// import Terms from "./pages/legal/Terms.js"
// import CookiePolicy from "./components/legal/CookiePolicy.js"
import NotFound from "./NotFound.js";
import Home from "./Home.js";
import Login from "./Login.js";
import Signup from "./Signup.js";
import ForgotPassword from "./ForgotPassword.js";
import Onboarding from "./Onboarding.js";
import WorkspaceInvite from "./WorkspaceInvite.js";
import PublicPage from "./PublicPage.js";
import ShowcaseNew from "./ShowcaseNew.js";
import ShowcaseEdit from "./ShowcaseEdit.js";
import Showcase from "./Showcase.js";
import Showcases from "./Showcases.js";
import Library from "./Library.js";
import Post from "./Post.js";
import Members from "./Members.js";
import Request from "./Request.js";
import Settings from "./Settings.js";
import Profile from "./Profile.js";
import { ProvideAuth } from "./use-firebase.js"

export default function App() {

  // let subdomain = getSubDomain(window.location.host);

  return (
    <ProvideAuth>
      <Router>
        <Container fluid style={{ marginTop: 0, backgroundColor: 'transparent' }}>
          <TopNavbar />
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch', marginTop: 60, marginLeft: 0, marginRight: 0 }}>
            <Sidebar />
            <Col style={{ width: '85%', padding: 0 }}>
              <Routes>
                <Route path="/notfound" element={<NotFound />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/workspaceinvitation" element={<WorkspaceInvite />} />
                <Route path="/request/:id" element={<Request />} />
                <Route path="/p/:id" element={<PublicPage />} />
                <Route path="/p/:id/:embed" element={<PublicPage />} />
                <Route path="/showcases" element={<Showcases />} />
                <Route path="/showcases/new" element={<ShowcaseNew />} />
                <Route path="/showcases/new/:id" element={<ShowcaseNew />} />
                <Route path="/showcases/edit/:id" element={<ShowcaseEdit />} />
                <Route path="/showcase/:id" element={<Showcase />} />
                <Route path="/library" element={<Library />} />
                <Route path="/post/:id" element={<Post />} />
                <Route path="/members" element={<Members />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Col>
          </Row>
        </Container>
      </Router>
    </ProvideAuth>
  );
}


// <Route path="/privacy" element={<Privacy />} />
// <Route path="/terms" element={<Terms />} />
// <Route path="/showcases" element={<Showcases />} />
// <Route path="/walls" element={<Walls />} />
// <Route path="/walls/new" element={<WallsNew />} />
// <Route path="/w/:slug" element={<PublicWall />} />
// <Route path="/wall/:slug" element={<Wall />} />
