import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get_first_name, useMediaQuery, numberWithCommas } from './shared-functions.js';
import { db } from "./use-firebase.js";
import { useRequireAuth } from "./use-require-auth.js";
import { collection, where, query, getDocs, orderBy } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Image, Modal, Form, Spinner, Card, Table } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { Copy, Eye, Megaphone, UsersThree, Heart, ArrowRight, PencilSimple } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';

export default function Home() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 960px)');
  const [numPosts, setNumPosts] = useState(0);
  const [numShowcases, setNumShowcases] = useState(0);
  const [numViews, setNumViews] = useState(0);
  const [latestPosts, setLatestPosts] = useState([]);
  const [showcases, setShowcases] = useState([]);
  const [postsInShowcase, setPostsInShowcase] = useState([]);

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {
      // check if onboarded or not
      if (!auth.user.onboarded) {
        navigate('/onboarding');
      }
      // check if invited to a new workspace
      getDocs(query(collection(db, "members"), where("email", "==", auth.user.email), where("joined", "==", false), where("declined", "==", false))).then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          navigate('/workspaceinvitation');
        }
      });

      // find posts
      getDocs(query(collection(db, "posts"), where("workspaceId", "==", auth.workspace.id), orderBy("createdTs", "desc") )).then((querySnapshot) => {
        setNumPosts(querySnapshot.docs.length);
        let _latestPosts = []
        let _postsInShowcase = []
        querySnapshot.forEach((doc) => {
          if (_latestPosts.length < 3) {
            _latestPosts.push(doc.data());
          }
          let found = false
          _postsInShowcase.forEach((p, i) => {
            if (p.showcaseId === doc.data().showcaseId) {
              p.count += 1;
              found = true;
            }
          });
          if (!found) {
            _postsInShowcase.push({ showcaseId: doc.data().showcaseId, count: 1 });
          }
        });
        setLatestPosts(_latestPosts);
        setPostsInShowcase(_postsInShowcase);
      });

      // find showcases
      getDocs(query(collection(db, "showcases"), where("workspaceId", "==", auth.workspace.id), orderBy("createdTs", "desc") )).then((querySnapshot) => {
        let _showcases = []
        setNumShowcases(querySnapshot.docs.length);
        querySnapshot.forEach((doc) => {
          _showcases.push(doc.data())
        });
        setShowcases(_showcases);
      });

      // count views
      getDocs(query(collection(db, "views"), where("workspaceId", "==", auth.workspace.id))).then((querySnapshot) => {
        setNumViews(querySnapshot.docs.length);
      });

    }
    window.scrollTo(0,0);
  }, [auth]);

  const getPostCount = (_id) => {
    let count = 0;
    postsInShowcase.forEach((p, i) => {
      if (p.showcaseId === _id) {
        count = p.count;
      }
    });
    return count;
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-right",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-right",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };


  if (!auth || !auth.user || !auth.workspace) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <h3>Welcome back, { get_first_name(auth.user.name) }!</h3>
          </Row>
          { (!auth.workspace.logoUrl || numShowcases === 0 || numPosts === 0) &&
            <Card style={{ backgroundColor: '#d2f8d266', padding: '20px 20px', marginTop: 20, width: '100%' }}>
              <h4>Your success checklist</h4>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20, textDecoration: auth.workspace.logoUrl ? 'line-through' : 'none' }}>
                <p>- Add a workspace logo</p>
                <p style={{ marginLeft: 10 }}><a href="/settings">Upload now <ArrowRight weight="bold" style={{ marginLeft: 2 }} /></a></p>
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10, textDecoration: numShowcases > 0 ? 'line-through' : 'none' }}>
                <p>- Create a showcase</p>
                <p style={{ marginLeft: 10 }}><a href="/showcases/new">Create now <ArrowRight weight="bold" style={{ marginLeft: 2 }} /></a></p>
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10, textDecoration: numPosts > 0 ? 'line-through' : 'none' }}>
                <p>- Create a video post</p>
                <p style={{ marginLeft: 10 }}><a href="/showcases">Create now <ArrowRight weight="bold" style={{ marginLeft: 2 }} /></a></p>
              </Row>
            </Card>
          }
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 40, marginLeft: 0, marginRight: 0 }}>
            <Col xs={6} sm={6} md={6} lg={4} xl={3} style={{ marginBottom: 20 }}>
              <Card style={{ backgroundColor: '#FFFFFF', border: '1px solid #EEEEEE' }}>
                <h6>Posts</h6>
                <h3>{ numberWithCommas(numPosts) }</h3>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} xl={3} style={{ marginBottom: 20 }}>
              <Card style={{ backgroundColor: '#FFFFFF', border: '1px solid #EEEEEE' }}>
                <h6>Showcases</h6>
                <h3>{ numberWithCommas(numShowcases) }</h3>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} xl={3} style={{ marginBottom: 20 }}>
              <Card style={{ backgroundColor: '#FFFFFF', border: '1px solid #EEEEEE' }}>
                <h6>Views</h6>
                <h3>{ numberWithCommas(numViews) }</h3>
              </Card>
            </Col>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
            <Col xs={6} sm={6} md={6} lg={4} xl={4} style={{ marginBottom: 20 }}>
              <Card style={{ padding: 20, backgroundColor: '#fea3aa44', cursor: 'pointer', minHeight: 160 }} onClick={() => navigate('/showcases/new/1')}>
                <Image src={'/assets/icons/megaphone.svg'} style={{ height: 30, width: 30 }} />
                <h5 style={{ marginTop: 10, maxWidth: 200 }}>Create a <span className="bold">customer testimonials</span> showcase</h5>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} xl={4} style={{ marginBottom: 20 }}>
              <Card style={{ padding: 20, backgroundColor: '#b2cefe44', cursor: 'pointer', minHeight: 160 }} onClick={() => navigate('/showcases/new/3')}>
                <Image src={'/assets/icons/users-three.svg'} style={{ height: 30, width: 30 }} />
                <h5 style={{ marginTop: 10, maxWidth: 200 }}>Create a <span className="bold">product reviews</span> showcase</h5>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} xl={4} style={{ marginBottom: 20 }}>
              <Card style={{ padding: 20, backgroundColor: '#f8b88b44', cursor: 'pointer', minHeight: 160 }} onClick={() => navigate('/showcases/new/2')}>
                <Image src={'/assets/icons/heart.svg'} style={{ height: 30, width: 30 }} />
                <h5 style={{ marginTop: 10, maxWidth: 200 }}>Create a <span className="bold">life at work</span> showcase</h5>
              </Card>
            </Col>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
            <p className="small" style={{ cursor: 'pointer' }} onClick={() => navigate('/showcases/new')}>View more templates <ArrowRight size={12} /></p>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
            <h4>My recent showcases</h4>
            <Button variant="primary" style={{ marginLeft: 20 }} onClick={() => navigate('/showcases/new')}>+ Create new</Button>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 10, marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              { showcases.length === 0 &&
                <p style={{ marginTop: 10 }}>None yet</p>
              }
              { showcases.length > 0 &&
                <Table borderless responsive striped variant="light" style={{ backgroundColor: '#FFFFFF' }}>
                  <thead>
                    <tr style={{ fontFamily: 'SF Bold' }}>
                      <th>Name</th>
                      <th>Posts</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      showcases.map((c, id) => {
                        return (
                          <tr key={id}>
                            <td>
                              <Image src={c.icon} style={{ height: 18, width: 18, marginRight: 16 }} />
                              <a href={"/showcase/" + c.id}>{ c.name }</a>{ !c.publicVisibility && <span style={{ marginLeft: 10 }} className="small muted">hidden</span> }
                            </td>
                            <td>{ getPostCount(c.id) }</td>
                            <td>
                              { c.publicVisibility && <a href={"/p/" + c.id} target="_blank"><Eye size={16} style={{ marginRight: 10, cursor: 'pointer' }} /></a> }
                              { c.publicVisibility && <Copy size={16} style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/p/" + c.id); doToastSuccess('Public page URL copied to clipboard') }} /> }
                              <PencilSimple size={16} style={{ cursor: 'pointer' }} onClick={() => { navigate('/showcases/edit/' + c.id) }} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              }
              { showcases.length > 0 &&
                <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
                  <p className="small" style={{ cursor: 'pointer' }} onClick={() => navigate('/showcases')}>View all <ArrowRight size={12} /></p>
                </Row>
              }
            </Col>
          </Row>
        </Col>
      </Row>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  )

}

// { false &&
//   <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
//     <Col xs={11} sm={11} md={11} lg={11} xl={11}>
//       <h5>Latest posts</h5>
//       <div style={{ marginTop: 10 }}>
//         {
//           latestPosts.map((p) => {
//             return (
//               <p><a href={'/post/' + p.id } target="_blank">{ p.name }</a> <span className="muted">created <Moment fromNow>{ p.createdTs }</Moment></span></p>
//             )
//           })
//         }
//       </div>
//       <h5 style={{ marginTop: 20 }}>Latest collections</h5>
//       <div style={{ marginTop: 10 }}>
//         {
//           latestShowcases.map((w) => {
//             return (
//               <p><a href={'/wall/' + w.slug } target="_blank">{ w.name }</a> <span className="muted">created <Moment fromNow>{ w.createdTs }</Moment></span></p>
//             )
//           })
//         }
//       </div>
//       <p className="small" style={{ marginTop: 10 }}><a href="/collections">View all</a></p>
//       <h5 style={{ marginTop: 20 }}>Latest collections</h5>
//       <div style={{ marginTop: 10 }}>
//         {
//           latestShowcases.map((c) => {
//             return (
//               <p><a href={'/collection/' + c.id } target="_blank">{ c.name }</a> <span className="muted">created <Moment fromNow>{ c.createdTs }</Moment></span></p>
//             )
//           })
//         }
//       </div>
//       <p className="small" style={{ marginTop: 10 }}><a href="/collections">View all</a></p>
//     </Col>
//   </Row>
// }
