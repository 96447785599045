import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from './shared-functions.js';
import { db } from "./use-firebase.js";
import { collection, getDocs, where, query, addDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Table, Spinner, Image } from 'react-bootstrap';
// import Footer from './components/common/Footer.js';
import { GlobeSimple, TwitterLogo, FacebookLogo, InstagramLogo, LinkedinLogo, SortAscending, SortDescending } from "phosphor-react";
// import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { XMasonry, XBlock } from "react-xmasonry";
import PostCard from "./components/cards/Post.js";

export default function PublicPage() {

  const navigate = useNavigate();
  const props = useParams();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [showcase, setShowcase] = useState(null);
  const [workspace, setWorkspace] = useState(null);
  const [posts, setPosts] = useState([]);
  const [tags, setTags] = useState([]);
  const [filterTag, setFilterTag] = useState('All');
  const [postOrder, setPostOrder] = useState('ascending');

  useEffect(() => {
    // get showcase
    if (props === undefined || props.id === undefined) {
      navigate('/notfound');
    } else {
      getDocs(query(collection(db, "showcases"), where("id", "==", props.id), where("publicVisibility", "==", true), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            setShowcase(doc.data());
            // get posts
            getDocs(query(collection(db, "posts"), where("approved", "==", true), where("publicVisibility", "==", true), where("showcaseId", "==", doc.data().id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot2) => {
              let _posts = [];
              let _tags = [];
              querySnapshot2.forEach((doc2) => {
                _posts.push(doc2.data());
                if (doc2.data().tags) {
                  doc2.data().tags.forEach((t, i) => {
                    if (!_tags.includes(t.text)) {
                      _tags.push(t.text);
                    }
                  });
                }
              });
              setTags(_tags);
              if (props && props.embed && props.embed) {
                setPosts(_posts.sort((a, b) => (b.createdTs > a.createdTs) ? 1 : -1).splice(0,6));
              } else {
                setPosts(_posts.sort((a, b) => (b.createdTs > a.createdTs) ? 1 : -1));
              }
            });
            // get workspace
            getDocs(query(collection(db, "workspaces"), where("id", "==", doc.data().workspaceId), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot3) => {
              querySnapshot3.forEach((doc3) => {
                setWorkspace(doc3.data());
              });
            });
            // record view
            if (window.location.hostname !== 'localhost') {
              addDoc(collection(db, "views"), { showcaseId: props.id, workspaceId: doc.data().workspaceId, viewTs: Date.now(), visibility: process.env.REACT_APP_FIREBASE_DB_CODE });
            }
          });

        } else {
          navigate('/notfound');
        }
      });
    }
    window.scrollTo(0,0);
  }, [props]);

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-right",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-right",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (!showcase || !workspace) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  // if (props && props.embed && props.embed === 'embed') {
  //   return (
  //     <ResponsiveMasonry columnsCountBreakPoints={{ 650: 1, 850: 2, 1000: 3 }} style={{ width: '100%', padding: 20 }}>
  //       <Masonry gutter="20px">
  //         {
  //           posts.map((p) => {
  //             if (filterTag === 'All' || p.tags.some(t => t.text === filterTag)) {
  //               return (
  //                 <PostCard post={p} public />
  //               )
  //             }
  //           })
  //         }
  //       </Masonry>
  //     </ResponsiveMasonry>
  //   )
  // }

  if (props && props.embed && props.embed === 'embed') {
    return (
      <div style={{ width: '100%', marginBottom: 40, backgroundColor: 'transparent' }}>
        <XMasonry maxColumns={4} responsive="true" targetBlockWidth={300}>
            {
              posts.map((p) => {
                if (filterTag === 'All' || p.tags.some(t => t.text === filterTag)) {
                  return (
                    <XBlock key={ p.id } width={ p.type === 'video' || p.type === 'url' ? 1 : 1}>
                      <PostCard post={p} public />
                    </XBlock>
                  )
                }
              })
            }
        </XMasonry>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 40, width: '100%' }}>
          <Col style={{ textAlign: 'center' }}>
            <p className="muted small"><a href={"https://app.nikos.ai/p/" + props.id} target="_blank" style={{ cursor: 'pointer' }}>View all</a></p>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 40, width: '100%' }}>
          <Col style={{ textAlign: 'center' }}>
            <p className="muted small"><a href="https://www.nikos.ai" target="_blank" style={{ backgroundColor: '#90EE9044', padding: '4px 6px', cursor: 'pointer' }}>Powered by Nikos</a></p>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginTop: -20 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
            { workspace && workspace.logoUrl &&
              <Image src={ workspace.logoUrl } style={{ height: 40, objectFit: 'cover' }} />
            }
            { workspace && !workspace.logoUrl &&
              <h2>{ workspace.name }</h2>
            }
            <h3 style={{ marginTop: 30 }}>{ showcase.name }</h3>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10, width: '100%' }}>
              <Col xs={10} sm={9} md={8} lg={7} xl={6} style={{ textAlign: 'left', padding: 0 }}>
                <p className="small muted">{ showcase.description }</p>
              </Col>
            </Row>
            { (workspace.webUrl || workspace.linkedinHandle || workspace.twitterHandle || workspace.facebookHandle || workspace.instagramHandle) &&
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 20, marginLeft: 0, marginRight: 0, marginBottom: 20 }}>
                <Col style={{ padding: 0 }}>
                  { workspace.webUrl &&
                    <a href={workspace.webUrl} target="_blank"><GlobeSimple size={20} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { workspace.linkedinHandle &&
                    <a href={'https://www.linkedin.com/company/' + workspace.linkedinHandle} target="_blank"><LinkedinLogo size={20} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { workspace.twitterHandle &&
                    <a href={'https://www.twitter.com/' + workspace.twitterHandle} target="_blank"><TwitterLogo size={20} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { workspace.facebookHandle &&
                    <a href={'https://www.facebook.com/' + workspace.facebookHandle} target="_blank"><FacebookLogo size={20} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { workspace.instagramHandle &&
                    <a href={'https://www.instagram.com/' + workspace.instagramHandle} target="_blank"><InstagramLogo size={20} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                </Col>
              </Row>
            }
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 0, marginLeft: 0, marginRight: 0 }}>
            <Col xs={10} sm={10} md={10} lg={11} xl={11} style={{ paddingLeft: 0 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                <p className="small" style={{ marginRight: 10, marginBottom: 5, borderRadius: 20, backgroundColor: filterTag === "All" ? 'lightgreen' : '#eeeeee', padding: '5px 15px', cursor: 'pointer' }} onClick={() => setFilterTag('All')}>All</p>
                {
                  tags.map((t, id) => {
                    return (
                      <p className="small" style={{ marginRight: 10, marginBottom: 5, borderRadius: 20, backgroundColor: t == filterTag ? 'lightgreen' : '#eeeeee', padding: '5px 15px', cursor: 'pointer' }} onClick={() => setFilterTag(t)}>#{ t }</p>
                    )
                  })
                }
              </Row>
            </Col>
            { false &&
              <Col xs={2} sm={2} md={2} lg={1} xl={1} style={{ textAlign: 'right' }}>
                { postOrder === 'ascending' &&
                  <SortAscending size={22} color={"#999999"} style={{ cursor: 'pointer' }} onClick={() => { setPostOrder('descending'); setPosts(posts.sort((a, b) => (a.createdTs > b.createdTs) ? 1 : -1)); }} />
                }
                { postOrder === 'descending' &&
                  <SortDescending size={22} color={"#999999"} style={{ cursor: 'pointer' }} onClick={() => { setPostOrder('ascending'); setPosts(posts.sort((a, b) => (b.createdTs > a.createdTs) ? 1 : -1)); }} />
                }
              </Col>
            }
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 10 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <XMasonry maxColumns={"Infinity"} responsive="true" center="true" targetBlockWidth={200}>
                  {
                    posts.map((p) => {
                      if (filterTag === 'All' || (p.tags && p.tags.some(t => t.text === filterTag))) {
                        return (
                          <XBlock key={ p.id } width={2}>
                            <PostCard post={p} public />
                          </XBlock>
                        )
                      }
                    })
                  }
              </XMasonry>
            </Col>
          </Row>
        </Col>
      </Row>
      <section className="footer-text" style={{ width: '100%', color: '#111111', paddingTop: 40, paddingLeft: 30, paddingRight: 30, paddingBottom: 40, textAlign: 'center' }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <Col style={{ textAlign: 'center' }}>
            <p className="muted small"><a href="https://www.nikos.ai" target="_blank" style={{ backgroundColor: '#90EE9044', padding: '6px 10px', border: '1px solid #90EE90', cursor: 'pointer' }}>Powered by Nikos</a></p>
          </Col>
        </Row>
      </section>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  )

}


// <ResponsiveMasonry columnsCountBreakPoints={{ 650: 1, 850: 2, 1000: 3 }} style={{ width: '100%', padding: 20 }}>
//   <Masonry gutter="20px">
//     {
//       posts.map((p) => {
//         if (filterTag === 'All' || p.tags.some(t => t.text === filterTag)) {
//           return (
//             <PostCard post={p} public />
//           )
//         }
//       })
//     }
//   </Masonry>
// </ResponsiveMasonry>
