import { useState, useEffect } from 'react'

export function generateRandomString(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function hexEncode(str) {
  var hex, i;
  var result = "";
  for (i = 0; i < str.length; i++) {
      hex = str.charCodeAt(i).toString(16);
      result += ("000"+hex).slice(-4);
  }
  return result
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateUrl(url) {
  var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  var regex = new RegExp(expression);
  if (url !== null && url.match(regex)) {
    return true
  } else {
    return false
  }
}

export function minutesToHM(d) {
    d = Number(d);
    var h = Math.floor(d / 60);
    var m = Math.floor(d % 60);
    var hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hrs ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
    return hDisplay + mDisplay;
}

export function get_first_name(str) {
  let words = str.split(" ")
  return(words[0])
}

export function numberWithCommas(x) {
  if (x !== undefined) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return x
  }
}

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

export function getSubDomain(h) {
  let parts = h.split(".");
  let subdomain = 'none';
  if (parts.length === 1) { // localhost:3000
    subdomain = 'none';
  }
  if (parts.length === 2) { // www.localhost:3000 or hello.localhost:3000 or nikos.ai
    if (parts[1] === 'localhost:3000') {
      if (parts[0] !== 'www') {
        subdomain = parts[0];
      } else {
        subdomain = 'none';
      }
    } else {
      subdomain = 'none';
    }
  }
  if (parts.length === 3) { // www.nikos.ai or hello.nikos.ai
    if (parts[0] === 'www') {
      subdomain = 'none';
    } else {
      subdomain = parts[0];
    }
  }
  return subdomain;
}

export function convertToSlug(t) {
  return t.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}
