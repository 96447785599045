import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import { db } from "./use-firebase.js";
import { useRequireAuth } from "./use-require-auth.js";
import { getDocs, collection, updateDoc, query, where, doc } from 'firebase/firestore'; // collection, addDoc, getDocs
import { RESERVED_HANDLES } from "./shared-constants.js";
import Footer from './components/common/Footer.js';
import Loading from './components/common/Loading.js';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Onboarding() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const [stepNum, setStepNum] = useState(0);
  const [workspaceName, setWorkspaceName] = useState(null);
  const [emailOne, setEmailOne] = useState(null);
  const [emailTwo, setEmailTwo] = useState(null);
  const [emailThree, setEmailThree] = useState(null);
  const [validated, setValidated] = useState(false);
  const [invitesValidated, setInvitesValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {
      setWorkspaceName(auth.workspace.name);
    }
  }, [auth]);

  const checkUniqueHandle = async (input_handle) => {
    let response = "hit"
    // reserved words
    if (RESERVED_HANDLES.includes(input_handle)) {
      // console.log("reserved");
      return response;
    }
    // check for unique handles
    await getDocs(query(collection(db, "users"), where("handle", "==", input_handle), where("uid", "!=", auth.user.uid))).then((querySnapshot) => {
      // console.log("found", querySnapshot.docs.length);
      if (querySnapshot.docs.length === 0) {
        response = "miss"
      } else {
        response = "hit"
      }
      // console.log("returning", response);
      return response;
    });
  }

  const handleWorkspaceNameValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        saveWorkspaceName();
      }
      setValidated(true);
    }
  };

  const saveWorkspaceName = () => {
    getDocs(query(collection(db, "workspaces"), where("id", "==", auth.user.uid))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        let _workspace = _doc.data();
        _workspace.name = workspaceName;
        updateDoc(doc(db, "workspaces", _doc.id), { name: workspaceName }).then(() => {
          auth.changeWorkspace(_workspace);
          setStepNum(2);
        });
      });
    });
  };

  const handleWorkspaceEmailValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        // saveTeamEmails();
        markOnboarded();
      }
      setInvitesValidated(true);
    }
  };

  const markOnboarded = () => {
    getDocs(query(collection(db, "users"), where("uid", "==", auth.user.uid))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        let _user = _doc.data();
        _user.onboarded = true;
        updateDoc(doc(db, "users", _doc.id), { onboarded: true }).then(() => {
          auth.updateUser(_user);
          pauseAndNavigateHome();
        });
      });
    });
  };

  const pauseAndNavigateHome = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate('/');
    }, 2000)
  }

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-right",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-right",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40, marginTop: 60 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, minHeight: '60vh' }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          { stepNum === 0 &&
            <div>
              <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
                <h3>Welcome!</h3>
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 100 }}>
                <Col xs={12} sm={10} md={8} lg={6} xl={4} style={{ padding: 0, textAlign: 'center' }}>
                  <p>Easily gather, organize and showcase your content with Nikos. Engage employees, attract talent, and build a brand from one workspace. For questions and comments, reach out to hello@nikos.ai.</p>
                  <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                    <Button variant="primary" onClick={() => setStepNum(1)}>Next</Button>
                  </Row>
                </Col>
              </Row>
            </div>
          }
          { stepNum === 1 &&
            <div>
              <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                <h3>Choose a name for your workspace</h3><p className="small muted">This could be your organization's name or a team name.<br />p.s. it will be displayed on all your public pages</p>
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10, marginBottom: 100 }}>
                <Col xs={12} sm={10} md={8} lg={6} xl={4} style={{ padding: 0, textAlign: 'center' }}>
                    <Form noValidate validated={validated} onSubmit={handleWorkspaceNameValidation} style={{ marginTop: 20 }}>
                      <Form.Group controlId="name">
                        <Form.Control required as="input" type="text" minLength="3" maxLength="96" value={workspaceName} onChange={(e) => setWorkspaceName(e.target.value)} />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Enter a valid name 3 to 96 characters in length
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                        <Button variant="primary" type="submit" style={{  }}>Next</Button>
                      </Row>
                    </Form>
                </Col>
              </Row>
            </div>
          }
          { stepNum === 2 &&
          <div>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
              <h3>Invite your coworkers</h3>
            </Row>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10, marginBottom: 100 }}>
              <Col xs={12} sm={10} md={8} lg={6} xl={4} style={{ padding: 0, textAlign: 'center' }}>
                  <Form noValidate validated={invitesValidated} onSubmit={handleWorkspaceEmailValidation} style={{ marginTop: 20 }}>
                    <Form.Group controlId="emailone">
                      <Form.Control as="input" type="email" minLength="5" maxLength="96" value={emailOne} onChange={(e) => setEmailOne(e.target.value)} />
                      <Form.Control.Feedback type="invalid">
                        Enter a valid email
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="emailtwo" style={{ marginTop: 20 }}>
                      <Form.Control as="input" type="email" minLength="5" maxLength="96" value={emailTwo} onChange={(e) => setEmailTwo(e.target.value)} />
                      <Form.Control.Feedback type="invalid">
                        Enter a valid email
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="emailthree" style={{ marginTop: 20 }}>
                      <Form.Control as="input" type="email" minLength="5" maxLength="96" value={emailThree} onChange={(e) => setEmailThree(e.target.value)} />
                      <Form.Control.Feedback type="invalid">
                        Enter a valid email
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                      <Button variant="primary" type="submit" style={{  }}>Complete setup</Button>
                    </Row>
                    <p className="small bold muted" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => markOnboarded()}>SKIP</p>
                  </Form>
              </Col>
            </Row>
          </div>
          }
        </Col>
      </Row>
      <Footer />
    </Row>
  );

}


// Handle
// <div>
//   <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
//     <h3>Choose your unique handle</h3>
//   </Row>
//   <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 100 }}>
//     <Col xs={12} sm={10} md={8} lg={6} xl={4} style={{ padding: 0 }}>
//       <Form onSubmit={handleSubmit} style={{ marginTop: 20 }}>
//         <InputGroup className="mb-3">
//           <InputGroup.Text id="handle" style={{ backgroundColor: '#FFFFFF', color: '#111111', borderWidth: 0, borderRadius: 0 }}>nikos.ai/</InputGroup.Text>
//           <Form.Control required as="input" type="text" minLength="3" maxLength="24" value={handle} onChange={(e) => setHandle(e.target.value.replace(/[^a-zA-Z0-9]/g,'').replace(/\s/g, ''))} />
//         </InputGroup>
//         { error &&
//           <p className="small" style={{ color: 'red', marginTop: -10 }}>Choose a unique handle 3 to 24 characters long</p>
//         }
//         <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
//           <Button variant="primary" type="submit" style={{  }}>Save</Button>
//         </Row>
//
//       </Form>
//     </Col>
//   </Row>
// </div>
// Handle validation
// const handleSubmit = (event) => {
//   if (event) {
//     const form = event.currentTarget;
//     event.preventDefault();
//     event.stopPropagation();
//     if (form.checkValidity() === true) {
//       if (RESERVED_HANDLES.includes(handle)) {
//         setError(true);
//       } else {
//         getDocs(query(collection(db, "users"), where("handle", "==", handle), where("uid", "!=", auth.user.uid))).then((querySnapshot) => {
//           if (querySnapshot.docs.length === 0) {
//             setError(false);
//             getDocs(query(collection(db, "users"), where("uid", "==", auth.user.uid))).then((querySnapshot) => {
//               querySnapshot.forEach((_doc) => {
//                 updateDoc(doc(db, "users", _doc.id), { handle: handle }).then(() => {
//                   doToastSuccess("Handle saved!");
//                   navigate('/connectwallet');
//                 });
//               });
//             });
//           } else {
//             setError(true);
//           }
//         });
//       }
//     }
//     setValidated(true);
//   }
// };
